@import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

.trialbalancetable,
.cashflowtable {
    .table {
        th {
            font-size: $font-14;
            font-family: $theme-font-family-SourceSansPro-Semibold;
            border: none !important;
            padding: 4px 8px;
        }

        td {
            border: 1px solid $theme-border-color-1;
            font-size: $font-12;
            padding: 4px 8px;
            font-family: $theme-font-family-SourceSansPro-Regular;
        }
    }

    .table_head_border {
        border-right: 1px solid $theme-border-color-1 !important;
        border-left: 1px solid $theme-border-color-1 !important;
        border-top: 1px solid $theme-border-color-1 !important;
    }
}

.totaltrialbalancetable {
    .table {
        td {
            font-size: $font-12;
            padding: 4px 8px;
        }
    }
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-color-type: none !important;
    --bs-table-bg-type: none !important;
}

.border-right {
    border-right: 1px solid $theme-border-color-1
}

.fundflowtable {
    tbody tr:nth-child(odd) {
        background-color: $theme-light-voilet-color;
    }
}


.fundflowtable,
.sale_bill_one {
    .table {
        th {
            color: $theme-color-dark-gray;
            font-size: $font-14;
            border: none !important;
            background-color: $white-color;
            padding: 4px 8px;
            white-space: nowrap;
            font-family: $theme-font-family-SourceSansPro-Semibold;
        }

        td {
            color: $theme-color-dark-gray;
            border: 1px solid $theme-border-color-1 !important;
            font-size: $font-12;
            padding: 4px 8px;
        }
    }

    .table_head_border {
        border-right: 1px solid $theme-border-color-1 !important;
        border-left: 1px solid $theme-border-color-1 !important;
        border-top: 1px solid $theme-border-color-1 !important;
    }
}


.product_tax_table {
    .table {
        border: 1px solid $theme-border-color-1 !important;
        border-radius: 6px;

        th {
            color: $theme-color-dark-gray;
            font-size: $font-14;
            font-family: $theme-font-family-SourceSansPro-Semibold;
            // border: 1.5px solid $theme-border-color-1;
            background-color: $theme-light-voilet-color;
            padding: 4px 8px;
            // border-bottom:1.5px solid $theme-border-color-1 !important;
        }

        td {
            color: $theme-color-dark-gray;
            // border:1px solid $theme-border-color-1;
            font-size: $font-12;
            padding: 4px 8px;
        }
    }

    // table {
    //     border-collapse:separate;
    //     border:1px solid $theme-border-color-1 !important;
    //     border-radius:6px;
    // }

    // td, th {
    //     border-left:solid black 1px;
    //     border-top:solid black 1px;
    // }

    //     th {
    //         color: $theme-color-dark-gray;
    //         font-size: $font-14;
    //         font-family: $theme-font-family-SourceSansPro-Semibold;
    //         border: 1.5px solid $theme-border-color-1;
    //         background-color: $theme-light-voilet-color;
    //         padding: 4px 8px;
    //         border-bottom: 1.5px solid $theme-border-color-1 !important;
    //     }

    // td:first-child, th:first-child {
    //      border-left: none;
    // }
}

.Financial_statement {
    width: 100%;
    height: calc(100vh - 160px) !important;
}

.transactiontable {
    .table {
        tr {
            color: $theme-color-dark-gray;
            font-size: $font-14;
            font-family: $theme-font-family-SourceSansPro-Semibold;
            background-color: $white-color;
            padding: 4px 8px;
        }

        td {
            color: $theme-color-dark-gray;
            border: 1px solid $theme-border-color-1;
            background-color: $white-color;
            font-size: $font-12;
            padding: 4px 8px;
            font-family: $theme-font-family-SourceSansPro-Regular;
        }
    }

}

.JournalVouchertable {
    .table {
        td {
            color: $theme-color-dark-gray;
            font-size: $font-12;
            padding: 4px 8px;
        }
    }
}

.border-right-0 {
    border-right: 0px !important;
}

.border-left-0 {
    border-left: 0px !important;
}