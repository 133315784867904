@import "variables";

.header-wrap
{
    border: 2px solid $color-primary !important;
    background-color: $theme-lightergray-bg-color !important;
    border-radius: 10px !important;
    // height: 70px;
.left-header {
    img {
        margin-top: 0px !important;
        height: auto !important;
        aspect-ratio: 1 / 1;
      }
}
    .logo2 
    {
        img {
            height: 38px !important;
            position: relative;
        }
    }

    .nav-right{
        ul{
           .maximize{
                svg{
                    stroke: #4f39cd !important;
                }
            } 
        } 
    }
}

