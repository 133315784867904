
.form-check-input:focus {
    box-shadow: none !important;
    outline: none !important;
}
.form-check-input:checked {
    background-color: $white-color !important;
    border-color: $color-primary !important;
}
.form-check-label {
    color:$theme-color-dark-gray;
    font-family:$theme-font-family-SourceSansPro-Regular;
    font-size: $font-12;
    cursor: pointer;
  
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.464' height='7.069' viewBox='0 0 10.464 7.069'%3E%3Cpath id='Path_1214' data-name='Path 1214' d='M654.936,653.028l.521.521,1.564,1.564,1,1,1.869-1.869.26-.26,1.251-1.252,1.877-1.877' transform='translate(-653.876 -649.794)' fill='none' stroke='%239283eb' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E") !important;   
    background-size: 66% 66% !important; 
    background-position: center !important;
}
  