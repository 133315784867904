.f-9{
    font-size: $font-9;
}
.f-10{
    font-size: $font-10;
}
.f-11{
    font-size: $font-11;
}
.f-12{
    font-size: $font-12;
}
.f-13
{
    font-size: $font-13;
}
.f-14
{
 font-size: $font-14;
}
.f-15
{
 font-size: $font-15;
}
.f-16
{
 font-size: $font-16;
}
.f-18{
    font-size:$font-18;
}
.f-21{
    $font-size:21px;
}
.f-28
{
   $font-size:28px;
}



.theme-text-primary-blue{
   color: $theme-text-primary-blue;
}
.theme-text-bg-color{
    background-color: $theme-text-bg-color ;
}
.theme-text-bg-gray{
    background-color: $theme-text-bg-gray ;
}
.theme-color-secondary{
    color: $color-secondary;
}

.input-lable{
    color: $theme-color-dark-gray;
    font-size: $font-14;
    font-weight: 600;
    position: relative;
    top: 5px;

}
.form-control{
    border :1px solid $input-br-color !important;
    box-shadow:0 0 3px -6px #000000 !important;
    border-radius: $br-8;
    // padding: 0.2rem 0.4rem;
}

.img-24 {
    width: 24px !important;
    height: 24px !important;
}

.h-2{
    height:2rem !important;
}

.ml_3{
    margin-left: -3px;
}

.px_0_8{
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.br-light-blue-color{
    border-color:$theme-br-light-blue !important;
}

// width
.w-11{
    width: 11%; 
}
.w-15{
    width: 15%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-38{
    width: 38%;
}
.w-55{
    width: 55%;
}
.w-70{
    width: 70%;
}

.w-36px{
    width: 36px;
}
.w-50px{
    width: 50px;
}

.w-60px{
    width: 60px;
}
.w-70px{
    width: 70px;   
}
.w-75px{
    width: 75px;
}
.w-90px{
    width: 90px;
}
.w-100px{
    width: 100px;
}
.w-104px{
    width: 104px;
}

.w-115px{
    width: 115px;
}

.w-120px{
    width: 120px;
}
.w-125{
    width: 125px;
}
.w-130px{
    width: 130px;
}
.w-135px{
    width: 135px;
}
.w-137px{
    width: 137px;
}
.w-155px{
    width: 155px;
}
.w-175px{
    width: 175px;
}
.w-inherit{
    width: inherit !important;
}

// height
.h-15{
    height: 15%;
}
.h-45px{
    height: 45px;
}
.h-150px{
    height: 150px;
}
.h-200px{
    height: 200px;
}

#nofCopiess{
    app-select{
        .form-select{
            width: 80px !important;
        }
    }
    
  }
  #lablesizes{
    app-select{
        .form-select{
            width: 100px !important;
        }
    }
  }
  #companies{
    app-input{
        .form-control{
            width: 150px !important;
        }
    }
  }

  #fontFamilyFirstInputs{
    app-select{
        .form-select{
          border:  none !important;
          height: 19px !important;
        }
    }
  }