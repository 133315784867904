//color variables
$theme-lightergray-bg-color:#F3F5F8;
$color-secondary:#7C89A4;
$theme-border-color:#CFD3DC;
$white-color:#FFFFFF;
$theme-color-dark-gray:#455472;
$theme-border-color-1:#DBDEE5;
$theme-border-color-2:#A8BFEC;
$input-br-color:#CFD3DC;
$border-color-gray:#DDE1E7;
$color-primary:#5843CF;
$color-primary-1:#4F39CD;
$color-primary-2:#0000FF; 
$theme-color-yellow:#FFB900;
$theme-background-color:#F6F6F6;
$theme-bg-light-purple-color:#F7F6FF;
$theme-border-light-purple-color:#E9E6FF;
$theme-light-voilet-color:#EEF1F7;
$theme-light-gray-color:#D9DDE3;
$theme-primary-red:#F92B35;
$theme-color-voilet:#9283EB;
$theme-color-gray:#D0D4DD;
$theme-color-red:#C70039;
$theme-navy-blue-color:#151B27;
$theme-color-sky-blue:#1492E6;
$theme-pink-color:#D12DB0;
$theme-color-gray-1:#B6C0D6;
$theme-color-gray-2: #A3ABB8;
$theme-color-green :#009094;
$theme-color-light-red:#E7284B;
$theme-color-light-green:#3ECE37;
$theme-color-darkgreen:#23AF1F;
$theme-color-sucess:#57B652;
$theme-color-Lavender-Blue:#E8ECF3;
$theme-color-orange:#F76F11;
$theme-light-gray-color-2 :#9CA9C2;
$theme-lighter-gray :#B6BCC8;
$theme-concrete-gray :#F3F3F3;
$theme-light-voilet-color-1:#F1EDFF;
$theme-color-blue:#5D47D8;
$theme-color-lightblue:#9e72ff78;
$theme-br-light-blue:#BDAAFE;
$theme-black-color:#000000;
$theme-text-primary-blue:#421CB7;
$theme-text-bg-color:#F5F2FF;
$theme-text-bg-gray :#D5E0F2;
$theme-light-pink-color:#DE58C2;
$theme-dark-pink-color:#C71AA4;
$dark-border-color:#707070;
$input-select-focus-color:#2eb5e2;
$table-text-success:#57B752;
$table-text-danger:#F92B35;
// font size
$font-9:9px;
$font-10:10px;
$font-11:11px;
$font-12:12px;
$font-13:13px;
$font-14:14px;
$font-15:15px;
$font-16:16px;
$font-18:18px;
$font-21:21px;
$font-size:21px;
$font-size:28px;

//border-radius
/* border - border-radius */
$border-radius-5px:5px;
$border-radius-8px:8px;
$border-radius-10px:10px;
$border-radius-12px:12px;
$border-radius-15px:15px;
$border-radius-20px:20px;

$br-8:8px;

$cursor_pointer :pointer;
$cursor_not_allowed: not-allowed;

/* font-family */
$theme-font-family-SourceSansPro-Regular: SourceSansPro-Regular;
$theme-font-family-SourceSansPro-Semibold: SourceSansPro-Semibold;
$theme-font-family-SourceSansPro-bold: SourceSansPro-bold;
