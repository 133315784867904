.nav-tabs {
    border-radius: 8px;
    margin-bottom: 0 !important;
    border: 1px solid $color-primary;
    background-color: $white-color;
    column-gap: 0rem;
    justify-content: flex-start;
    width: 37%;
    max-width: 180px;
    min-width: fit-content;
    .nav-link {
      padding: 4px 15px !important;
      // padding: 0.4rem 2.1rem !important;
    }
  
    a.nav-link {
      border-radius: 6px;
      font-weight: 600;
      // padding: 10px 30px;
      border: none;
      margin-bottom: 0;
      color: $color-primary;
      font-size: 12px !important;
      span{
        letter-spacing: 0.3px;
      }
      &.active {
        color: $white-color;
        background-color: $color-primary;
        font-weight: bold;
      }
      &:hover {
        background-color: $color-primary;
        color: $white-color;
      }
    }
  }

  