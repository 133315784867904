.button{
 border-radius: $br-8;
 outline: none;
 padding: 5px 16px;
 width: auto;
 font-size: $font-12;
}
.button-secondary
{
    border: 1px solid  $theme-border-color-1;
    color: $color-secondary;
    background-color: $white-color;
    font-weight: 500;
}

.button-add {
    border: 1px solid $color-primary;
    color:$color-primary;
    background-color: $white-color;
    font-weight: 500;
}

.company-btn{
    border-radius: 4px;
    outline: none;
    padding: 2px 10px;
    font-size: 11px ;
    background-color: #CB4E80;
    color: #fff;
    width: 30px;
    
}
.person-btn{
    border-radius: 4px;
    outline: none;
    padding: 2px 17px;
    width: 30px;
    font-size: 11px ;
    background-color: #1398EF;
    color: #fff
}
.common_button {
    height: 2rem;
    border-radius: $border-radius-8px;
    font-family: $theme-font-family-SourceSansPro-Semibold;
    font-size: $font-12;
    padding: 4px 16px;
    gap: 6px;
}

.blue_button {
    background-color: $color-primary;
    color: $white-color;
    border: none;
}
.light_blue_button {
    background-color: $theme-light-voilet-color-1;
    color: $theme-color-dark-gray;
    border: none;
    white-space: nowrap;
    width: auto;
}

.red_button {
    background-color: $theme-primary-red;
    color: $white-color;
    border: none;
}
.green_button {
    background-color: $theme-color-darkgreen;
    color: $white-color;
    border: none;
}
.white_button {
    background-color: $white-color;
    color: $color-secondary;
    border: 1px solid $theme-border-color-1;
}
.light_button {
    background-color: $theme-light-gray-color-2;
    color: $theme-black-color;
    border: 1px solid $theme-border-color-1;
}
.yellow_button {
    background-color: $theme-color-yellow;
    color: $theme-navy-blue-color;
    border: none;
}

.add_button{
    background-color: $white-color;
    color: $color-primary;
    border: 1px solid  $color-primary;
    height: 22px !important;
}

.white_button_BlueText {
    background-color: $white-color;
    color: $color-primary;
    border: 1px solid $theme-border-color-1;
}

.white_button_sky-blueText {
    background-color: $white-color;
    color: $theme-color-sky-blue;
}

.white_button_orangeText {
    background-color: $white-color;
    color: $theme-color-orange;
}

.white_button_redText {
    background-color: $white-color;
    color: $theme-color-red;
}

.white_button_greenText {
    background-color: $white-color;
    color: $theme-color-green;
}


