/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
@import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
@import "~bootstrap/dist/css/bootstrap.min.css";

@media print {
  body {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    width: 100%; /* Ensure content takes full width */
    height: 100%; /* Ensure content takes full height */
    box-sizing: border-box;
  }
}

:root {
  --login-bg-image-url: url("assets/images/login/js-background-login-img.png");
  --providedCompanyName: "JewelSteps";
}

.component-wrapper {
  // padding: 20px;
  background: #fff;
  min-height: 100%;
  border-radius: 47px;
  display: flex;
  flex-direction: column;
}

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

*::-webkit-scrollbar {
  // width: 10px;
  height: 3px !important;
  width: 0px;
  height: 9px !important;
}

@import "./assets/scss/custom/header";
@import "./assets/scss/custom/theme";
@import "./assets/scss/custom/variables";
@import "./assets/scss/custom/sidebar";
@import "./assets/scss/custom/nav-tab";
@import "./assets/scss/custom/button";
@import "./assets/scss/custom/table";
@import "./assets/scss/custom/checkbox";

@font-face {
  font-family: "SourceSansPro-Regular";
  src: local("SourceSansPro-Regular"),
    url(./assets/theme-font-family/SourceSansPro-Regular.otf) format("truetype");
}

@font-face {
  font-family: "SourceSansPro-Semibold";
  src: local("SourceSansPro-Semibold"),
    url(./assets/theme-font-family/SourceSansPro-Semibold.otf)
      format("truetype");
}

@font-face {
  font-family: "SourceSansPro-bold";
  src: local("SourceSansPro-bold"),
    url(./assets/theme-font-family/SourceSansPro-Bold.otf) format("truetype");
}

.ag-root-wrapper-body.ag-layout-normal {
  height: 100% !important;
  overflow: auto;
}

.page-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $theme-background-color !important;

  .page-body-wrapper {
    height: 100%;
    background-color: #fff !important;
    // p,
    // div {
    //     font-family: $theme-font-family-SourceSansPro-Regular;
    // }
  }
}
.ag-overlay-loading-center {
  content: counter("name");
}
.ag-overlay {
  z-index: 0 !important;
}
.page-wrapper {
  .page-header {
    .header-wrapper {
      .nav-right > ul > li {
        padding: 0px 8px !important;

        &:nth-last-child(2) {
          padding: 0px 0px 0px 8px !important;
        }
      }
    }
  }
}

.page-wrapper.horizontal-wrapper .header-logo-wrapper {
  width: 55px !important;
  height: 48px !important;
  border-right: 1px solid $border-color-gray;
}

.page-wrapper {
  .page-body-wrapper {
    .page-body {
      padding: 0 15px 0 15px;
    }

    // .flex-fill{
    //     flex: 1 0 auto !important;
    // }
  }
}

// color classes
.table-text-success {
  color: $table-text-success !important;
}
.text-yellow {
  color: $theme-color-yellow !important;
}
.table-text-danger {
  color: $table-text-danger !important;
}
.text-grey {
  color: $theme-light-gray-color-2 !important;
}

.text-primary {
  color: $color-primary !important;
}

.text-pink {
  color: $theme-pink-color !important;
}
.color-blue{
  color: $theme-color-blue;
}

.text-secondary {
  color: $color-secondary !important;
}

.text-gray-color {
  color: $theme-color-dark-gray !important;
}

.text-color-sky-blue {
  color: $theme-color-sky-blue;
}

.text-red {
  color: $theme-color-red !important;
}

.text-normal-red {
  color: $theme-primary-red;
}

.black-color {
  color: $theme-black-color !important;
}

.text-lighter-gray {
  color: $theme-lighter-gray !important;
}

.text-color-danger {
  color: $theme-primary-red !important;
}
.text-success {
  color: $theme-color-sucess !important;
}
.text-green {
  color: $theme-color-green;
}
.text-darkgreen {
  color: $theme-color-darkgreen;
}

.color-dark-gray {
  color: $theme-color-dark-gray;
}

.color-pink{
  color:  $theme-light-pink-color;
}

.bg-white {
  background-color: $white-color;
}

.primary-color-1 {
  color: $color-primary-1;
}

.bg-primary-1 {
  background-color: $color-primary-1;
}

.tabel-bg-color {
  background-color: $theme-light-voilet-color !important;
}
.bg-light-voilet-color {
  background-color: $theme-light-voilet-color-1 !important;
}

.bg-light-gray {
  background-color: $theme-light-gray-color;
}
.theme-color-gray{
  color: $theme-color-gray-1 !important;
}
.theme-color-gray-1 {
  background-color: $theme-color-gray-1;
}
.bg-gray {
  background-color: $theme-border-color-1 !important;
}

.border-right {
  border-right: 1px solid $border-color-gray;
}

.border-left {
  border-left: 1px solid $border-color-gray;
}

.border-gary-left {
  border-left: 1px solid $theme-border-color-1 !important;
}

.border-gray1-right {
  border-right: 1px solid $theme-border-color;
}

.border-gary-right {
  border-right: 1px solid $theme-border-color-1 !important;
}

.border-bottom-gray {
  border-bottom: 1px solid $theme-border-color;
}

.border-gray {
  border: 1px solid $theme-border-color-1 !important;
}
.border-dark-gray {
  border: 1px solid $theme-color-dark-gray !important;
}

.border-gray-2 {
  border: 1px solid $theme-border-color;
}
.border-right-line {
  border-right: 1px solid $color-secondary !important;
}

.border-primary {
  border: 1px solid $color-primary !important;
}

.border-sky-blue {
  border: 1px solid $theme-color-sky-blue !important;
}

.border-orange {
  border: 1px solid $theme-color-orange !important;
}

.border-primary-red {
  border: 1px solid $theme-primary-red !important;
}

.border-green {
  border: 1px solid $theme-color-green !important;
}

.border-right-0 {
  border-right: none !important;
}
.border-gray-color{
  border-color: $theme-color-dark-gray !important;;
}
.theme-bg-lightergray-color {
  // background-color: $theme-border-color;
  background-color: $theme-lightergray-bg-color;
}

.border-gray-1 {
  border-color: $theme-border-color;
  opacity: 0.8;
}

.border-left-1px {
  border-left: 1px solid #5f6e8b !important;
}
.border-right-1px {
  border-right: 1px solid #5f6e8b !important;
}

// font-family
.font-regular {
  font-family: $theme-font-family-SourceSansPro-Regular !important;
}

.font-semibold {
  font-family: $theme-font-family-SourceSansPro-Semibold !important;
}

.font-bold {
  font-family: $theme-font-family-SourceSansPro-bold !important;
}

.font-weight-unset {
  font-weight: unset !important;
}
//pointer class
.cursorPointer {
  cursor: $cursor_pointer;
}
.pointer_none {
  pointer-events: none !important;
}

.cursor-not-allowed {
  cursor: $cursor_not_allowed !important;
}

.h-0 {
  height: 0;
}

.h-28px {
  height: 28px !important;
}

.m-t-26 {
  margin-top: 26px;
}

.w-400 {
  width: 400px;
}

//padding class
.p-10 {
  padding: 10px;
}

.p-l-60 {
  padding-left: 60px;
}

.word-break {
  word-break: break-all;
}

.ag-cell {
  &.padding-right-10 {
    padding-right: 10px !important;
  }
}

// ******custom dropdown css start *****
.custom_dropdown {
  .multiselect-dropdown {
    .dropdown-btn {
      background-color: #fff;
      border: 1px solid $theme-border-color !important;
      border-radius: $border-radius-8px !important;
      padding: 3px 6px !important;
      height: 28px;
      font-size: $font-10;
      display: flex !important;
      align-items: center;


      .selected-item-container .selected-item {
        display: flex;
        align-items: center;
        text-wrap: nowrap;
        border: 1px solid rgba(166, 157, 242, 100%) !important;
        margin-right: 4px;
        background: $theme-light-voilet-color !important;
        padding: 0 5px;
        color: $theme-color-dark-gray !important;
        border-radius: $border-radius-5px !important;
        font-family: $theme-font-family-SourceSansPro-Regular !important;
        max-width: 100% !important;
        span {
          display: block;
          max-width: 93px !important;
        }
        a {
          color: $theme-color-dark-gray !important;
        }

        &:hover {
          box-shadow: none !important;
        }
      }

      .dropdown-multiselect--active .dropdown-multiselect__caret {
        -webkit-transform: rotateX(180deg) !important;
        transform: rotateX(180deg) !important;
      }
      .dropdown-multiselect__caret {
        line-height: normal !important;
        height: 28px !important;
        padding: 0px 8px !important;
        display: flex !important;
        align-items: center;
        justify-content: end;

        &::before {
          display: none;
        }

        &::after {
          content: "";
          display: inline-block;
          width: 12.109px;
          height: 6.554px;
          background-image: url("assets/svg/dropdown.svg");
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
    .dropdown-list {
      margin-top: 0px !important;
      overflow: hidden !important;
      li {
        display: flex;
        align-items: center;
        line-height: 1;
      }
      .multiselect-item-checkbox input[type="checkbox"] + div {
        font-size: $font-10;
        color: $theme-color-dark-gray !important;
      }

      .multiselect-item-checkbox input[type="checkbox"] + div:before {
        border: 2px solid $theme-color-gray !important;
        border-radius: 3px !important;
        zoom: 0.8 !important;
      }
      .multiselect-item-checkbox input[type="checkbox"] + div:after {
        border-width: 0 0 2px 2px;
        left: 2px;
        border-color: $color-primary;
      }
      .multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
        background-color: $white-color !important;
        border-radius: 3px !important;
        border: 1px solid $color-primary !important;
        top: 53% !important;
        left: 0px !important;
        zoom: 0.8 !important;
        box-shadow: none !important;
        outline: none !important;
      }
      .filter-textbox {
        padding: 5px 2px !important;
        input {
          font-size: $font-12;
          color: $theme-color-dark-gray !important;
        }
      }
    }

    .no-filtered-data,
    .no-data {
      h5 {
        font-size: 10px !important;
      }
    }
  }
}

// For ng-multi-dropdown with ICON
.dropdown-with-icon {
  .multiselect-dropdown {
    .dropdown-btn {
      background-color: #fff;
      border: 1px solid $theme-border-color !important;
      border-radius: $border-radius-8px !important;
      padding: 3px 6px !important;
      height: 28px;
      font-size: $font-10;
      display: flex !important;
      align-items: center;
      margin-left: -3px !important;  

      .selected-item-container .selected-item {
        display: flex;
        align-items: center;
        text-wrap: nowrap;
        border: 1px solid rgba(166, 157, 242, 100%) !important;
        margin-right: 4px;
        background: $theme-light-voilet-color !important;
        padding: 0 5px;
        color: $theme-color-dark-gray !important;
        border-radius: $border-radius-5px !important;
        font-family: $theme-font-family-SourceSansPro-Regular !important;
        max-width: 100% !important;
        span {
          display: block;
          max-width: 93px !important;
        }
        a {
          color: $theme-color-dark-gray !important;
        }

        &:hover {
          box-shadow: none !important;
        }
      }

      .dropdown-multiselect--active .dropdown-multiselect__caret {
        -webkit-transform: rotateX(180deg) !important;
        transform: rotateX(180deg) !important;
      }
      .dropdown-multiselect__caret {
        line-height: normal !important;
        height: 28px !important;
        padding: 0px 8px !important;
        display: flex !important;
        align-items: center;
        justify-content: end;

        &::before {
          display: none;
        }

        &::after {
          content: "";
          display: inline-block;
          width: 12.109px;
          height: 6.554px;
          background-image: url("assets/svg/dropdown.svg");
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
    .dropdown-list {
      margin-top: 0px !important;
      overflow: hidden !important;
      li {
        display: flex;
        align-items: center;
        line-height: 1;
      }
      .multiselect-item-checkbox input[type="checkbox"] + div {
        font-size: $font-10;
        color: $theme-color-dark-gray !important;
      }

      .multiselect-item-checkbox input[type="checkbox"] + div:before {
        border: 2px solid $theme-color-gray !important;
        border-radius: 3px !important;
        zoom: 0.8 !important;
      }
      .multiselect-item-checkbox input[type="checkbox"] + div:after {
        border-width: 0 0 2px 2px;
        left: 2px;
        border-color: $color-primary;
      }
      .multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
        background-color: $white-color !important;
        border-radius: 3px !important;
        border: 1px solid $color-primary !important;
        top: 53% !important;
        left: 0px !important;
        zoom: 0.8 !important;
        box-shadow: none !important;
        outline: none !important;
      }
      .filter-textbox {
        padding: 5px 2px !important;
        input {
          font-size: $font-12;
          color: $theme-color-dark-gray !important;
        }
      }
    }

    .no-filtered-data,
    .no-data {
      h5 {
        font-size: 10px !important;
      }
    }
  }
}

.single-select-no-checkbox {
  .multiselect-dropdown .dropdown-list .multiselect-item-checkbox input[type=checkbox] + div {
    &:before, &:after { display: none; }
    padding-left: 2px;
  }
  .dropdown-list .filter-textbox input { padding-left: 10px !important; }
}


// ******custom dropdown css end *****

// ********* checkbox css start **********
// .ag_grid_checkbox, .checkbox_component{
//     .form-check-input{
//         input[type=checkbox] {
//             background-color: $white-color !important;
//             border-color: $theme-border-color !important;
//             zoom: 1 !important;
//             border-radius: 3px !important;
//         }
//     }
//     .form-check-input:checked[type=checkbox] {
//         border-color: $color-primary !important;
//     }
// }
// ********* checkbox css start **********

// //**** ag grid table css start***

//     .ag_Childheader {
//         .ag-header-cell-text {
//             color: $theme-color-dark-gray !important;
//             font-family: $theme-font-family-SourceSansPro-Semibold !important;
//             font-size: $font-12 !important;
//             display: flex;
//             justify-content: center;
//             text-align: center;
//             margin-top: 2px;
//         }

//         .ag_headerClass {
//             border: none !important;
//             border-right: 0;
//         }
//     }

// By Rajshree Khadgi

// #Manufacture_Process{
//     app-ag-grid {
//         ag-grid-angular {
//             height: calc(100vh - 365px) !important;

//             .custom-column-group-cell-top {
//                 border-top: 1px solid #ccc !important;
//                 height: auto;
//               }

// .ag-header{
//     background-color: #FFFFFF;
// }
// .ag-header .ag-header-row-column{
//     border-top :1px solid $theme-border-color-1
// }

//                 .ag-row-even {
//                     background-color: $theme-light-voilet-color !important;
//                 }

//                 .ag-row-odd {
//                     background-color: $white-color !important;
//                 }

//         }
//     }
// }

// #Voucher_Tax {
//     app-ag-grid {
//         ag-grid-angular {
//             .ag-header {
//                 background-color: $theme-light-voilet-color !important;
//             }

//             .ag-row-even {
//                 background-color: $white-color !important;
//             }

//             .ag-row-odd {
//                 background-color: $white-color !important;
//             }

//             .ag-header-row {
//                 .ag-header-cell {
//                     border: none;
//                 }
//             }
//         }
//     }
// }

// #Sales_Invoice {
//     app-ag-grid {
//         ag-grid-angular {
//             .ag-row-even {
//                 background-color: $white-color !important;
//             }

//             .ag-row-odd {
//                 background-color: $white-color !important;
//             }

//             .ag-center-cols-container {
//                 margin: 0 !important;
//             }

//             .ag-body-horizontal-scroll {
//                 height: 0 !important;
//                 max-height: 0 !important;
//                 min-height: 0 !important;
//             }
//         }
//     }
// }

// ag-grid-angular {
//     .ag-root-wrapper {
//         border: 1px solid $theme-border-color-1 !important;

//         .ag-header {
//             background-color: $white-color !important;
//             border-bottom: none;
//         }
//     }
//     .ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total){
//         border-top:1px solid $theme-border-color-1 !important;
//     }

//     .ag-header-cell,
//     .ag-cell,
//     .ag_headerClass {
//         border: 1px solid $theme-border-color-1;
//         border-bottom: 0;

//         &:nth-child(1) {
//             border-left: 0 !important;
//         }
//     }
//     .ag-row {
//         border-bottom: 0 !important;
//         // height: 100% !important;
//         .ag-row-even {
//             background-color: $theme-light-voilet-color !important;
//         }

//         .ag-row-odd {
//             background-color: $white-color !important;
//         }
//     }
//     .ag-header-cell-text {
//         color: $theme-color-dark-gray !important;
//         font-family: $theme-font-family-SourceSansPro-Semibold !important;
//         font-size: $font-14;
//     }

//     .ag-cell {
//         color: $theme-color-dark-gray;
//         font-family: $theme-font-family-SourceSansPro-Regular;
//         font-size: $font-12;

//     }

//     .ag-header-group-cell-label {
//         color: $theme-color-dark-gray !important;
//         font-family: $theme-font-family-SourceSansPro-Semibold !important;
//         font-size: $font-14;
//     }
//   .ag-header-group-cell {
//     border-right:0 !important;
//   }
//     .ag-header-row {
//         font-weight: unset !important;
//     }

//     .ag-icon-menu::before {
//         display: none !important;
//     }

//     .ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper {
//         height: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//     }

//     .ag-cell-label-container {
//         padding: 2px 0px !important;
//     }

//     .ag-pinned-right-cols-container{
//         border-left: 1px solid $theme-border-color;
//         margin: 0 !important;

//         .ag-row {
//             height: 28px !important;
//         }
//     }
// }

// .voucherTax_Type {
//     .ag-header-cell-comp-wrapper {
//         justify-content: start !important;
//     }
// }

// .justify_content_end {
//     .ag-header-cell-comp-wrapper {
//         justify-content: end !important;
//     }
// }

// .justify_content_start {
//     .ag-header-cell-comp-wrapper {
//         justify-content: start !important;
//     }
// }

// //**** ag grid table css end***

// ****** style for ag-grid by pranay
ag-grid-angular {
  .ag-pinned-right-cols-container {
    margin: 0 !important;
  }

  .custom-column-group-cell {
    border-right: 1px solid $theme-border-color-1 !important;
    height: -webkit-fill-available;
    &.make-center {
      app-checkbox-renderer {
        div {
          justify-content: center !important;
        }
      }
    }
    // cursor: pointer;
  }

  .ag-ltr .ag-cell {
    border-right-width: 1px;
    color: #455472;
    font-family: "SourceSansPro-Semibold";
    font-size: 10px;
}

  .ag-root-wrapper {
    .ag-header {
      background-color: $white-color;

      .ag-header-row {
        color: $theme-color-dark-gray !important;
        font-family: $theme-font-family-SourceSansPro-Semibold;
        font-weight: unset !important;
        font-size: $font-14;

        .ag-header-cell-resize::after {
          height: 0 !important;
        }
      }
    }
  }

  .ag-header-row {
    .ag-header-cell {
      cursor: pointer;
      &.ag_header_end {
        .ag-header-cell-comp-wrapper {
          app-custom-header div.wrapper > div {
            justify-content: end;
          }
        }
      }
      &.ag_header_center {
        .ag-header-cell-comp-wrapper {
          app-custom-header div.wrapper > div {
            justify-content: center;
          }
        }
      }
    }
  }

  .ag-header-cell:not(.ag-header-cell-auto-height) {
    &.customHeaderClass {
      .ag-header-cell-comp-wrapper {
        align-items: baseline !important;
        padding-top: 8px !important;
      }
    }
  }

  .child_header {
    .clild_Label {
      font-size: $font-12 !important;
    }
  }
  .ag-floating-bottom {
    width: 100%;
    .ag-row {
      .ag-cell {
        color: $theme-color-dark-gray;
        font-family: $theme-font-family-SourceSansPro-Semibold;
        font-weight: unset !important;
        font-size: $font-14;
        overflow: hidden !important;
        display: flex !important;
        align-items: center !important;
        padding-top: 5px !important;
        justify-content: flex-end;
        .images_renderer,
        .status-cell-btn,
        .dateTimeRenderer,
        .trackOrderRenderer,
        .invoiceButtonRenderer,
        .view_icon_renderer,
        .dateRenderer {
          display: none !important;
        }
      }
    }
  }
  .ag-center-cols-container {
    width: 100%;
    .ag-row {
      .ag-cell {
        color: #455472;
        font-family: "SourceSansPro-Semibold";
        font-size: 10px;
        overflow: hidden !important;
        .ag-row-drag {
          margin: 0 !important;
        }
      }
      .customCellclass {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
      }
    }
  }

  .ag-row-even {
    background-color: $theme-light-voilet-color;
  }

  .ag-row-odd {
    background-color: $white-color;
  }

  .ag-header-check-center {
    padding-left: 10px !important;
  }
  .custom-column-space-cell {
    white-space: pre-wrap !important;
  }
}

// .ag-row-position-absolute {
//     position: absolute;
//     height: 28px !important;
//     font-size: 10px !important;
// }

.non-editable-cell {
  pointer-events: none;
  user-select: none;
}

.NotEditable {
  app-plus-icon {
    svg {
      cursor: not-allowed !important;
    }
  }
}
.kyc_report_grid{
  ag-grid-angular {
    .ag-header-row {
      .ag-header-cell {
        .ag-header-cell-comp-wrapper{
          justify-content:center !important;
          app-two-headerline-span{
            .text-start{
              margin:0 !important;
            }
          }
        }
      }
    }
    .ag-body{
      .ag-center-cols-viewport{
        .ag-cell-value{
          .action_renderer{
            height:100%  !important;
          }
        }
      }
    }
  }
}

// .Job_Work_Queue_grid {
//   ag-grid-angular {
//     .ag-header-row {
//       .ag-header-cell {
//         &.ag_header_fontSize {
//           .ag-header-cell-comp-wrapper {
//             app-custom-header div.wrapper > div > div {
//               font-size: 16px !important;
//               top: -5px !important;
//             }
//           }
//         }
//       }
//     }
//     .ag-center-cols-container .ag-row .ag-cell {
//       font-size: 16px !important;
//     }
//     app-ag-grid-inner-htmldisplay div {
//       font-size: 16px !important;
//     }
//     .ag_grid_custom_dropdown {
//       font-size: 16px !important;
//       // font-family: $theme-font-family-SourceSansPro-Semibold !important;
//     }
//   }
// }
// .Job_Work_Queue {
//   ag-grid-angular {
//     .ag-header-row {
//       .ag-header-cell {
//         &.ag_header_fontSize {
//           .ag-header-cell-comp-wrapper {
//             app-custom-header div.wrapper > div > div {
//               font-size: 22px !important;
//               // top: -7px !important
//               top: 1px !important
//             }
//           }
//         }
//       }
//     }
//     .ag-center-cols-container .ag-row .ag-cell {
//       font-size: 22px !important;
//     }
//     app-ag-grid-inner-htmldisplay div {
//       font-size: 22px !important;
//     }
//     .ag_grid_custom_dropdown {
//       font-size: 22px !important;
//       // font-family: $theme-font-family-SourceSansPro-Semibold !important;
//     }
//   }
// }

// #manufacturing_out{
//     ag-grid-angular {
//         height: calc(100vh - 316px) !important;
// }
// }

#accountLedgerReportMetal {
  ag-grid-angular {
    height: calc(100vh - 315px) !important;
  }
}

#UnknownStock {
  ag-grid-angular {
    height: 25rem !important;
  }
}
#customerDetailsGrid {
  ag-grid-angular {
    height: 24rem !important;
  }
}

#shareHoldersGrid {
  ag-grid-angular {
    height: 9rem !important;
  }
}

.Product_Characteristics {
  ag-grid-angular {
    .ag-popup-editor {
      height: 1.6rem;
      width: 8rem;
    }
  }
}
#Manufacture_Process {
  ag-grid-angular {
    height: calc(100vh - 235px) !important;
  }
}
#Manufacture_Process_user {
  ag-grid-angular {
    height: 144px !important;
  }
}

#MetalWiseOpening {
  ag-grid-angular {
    height: 36rem !important;
  }
}

#MetalWiseOpeningAccountLedger {
  ag-grid-angular {
    height: 15rem !important;
  }
}

#AdditionalAmount,
#ExpensesGrid,
#ReleaseAmount,
#PayInterest,
#SelectPrintFormat,
#Addon {
  ag-grid-angular {
    height: 12rem !important;
    .custom-column-group-cell {
      border-right: 0px !important;
    }
  }
}

.loans_popup {
  .modal-dialog {
    max-width: 900px;
    margin: auto;
  }
}

#metalFixingPopup {
  ag-grid-angular {
    height: calc(100vh - 460px) !important;
  }
}

#FundTransferpopup {
  ag-grid-angular {
    height: 19.4rem !important;
  }
}

.stock_journal_grid_height {
  height: calc(100vh - 325px);
}
.Job_Work_Queue {
  height: 10.9rem !important;
}
.Fund_Transfer_popup {
  height: 19.4rem !important;
}

.h-120px {
  height: 100px;
}

.width-100 {
  width: 100% !important;
}

.stock_journal_common_grid_height {
  height: calc(100vh - 325px);
}

#payment_list {
  ag-grid-angular {
    height: calc(100vh - 530px) !important;
  }
}
#Job_Work_Queue_grid {
  ag-grid-angular {
    height: 100px !important;
  }
}
#Quick_Job_Work_Queue_grid {
  ag-grid-angular {
    height: 200px !important;
  }
}
#addDiamond {
  ag-grid-angular {
    height: calc(100vh - 250px) !important;
  }
}

#ModalHeight {
  ag-grid-angular {
    height: calc(100vh - 500px) !important;
  }
}

#ModalFixHeight {
  ag-grid-angular {
    height: calc(100vh - 585px) !important;
  }
}

#LegderFixHeight {
  ag-grid-angular {
    height: 13rem!important;
  }
}
#AppraisalHeight {
  ag-grid-angular {
    height: 10rem !important;
  }
}

// ****** style for ag-grid by pranay
.gray-input {
  .form-control:disabled {
    background-color: $theme-light-voilet-color !important;
    opacity: 1;
    border-radius: 0px !important;
  }
}
// ******pagination css start******

.grid-pagination {
  .page-link {
    border-radius: 50% !important;
    color: $theme-color-dark-gray;
    box-shadow: none !important;
    outline: none !important;
    font-size: $font-11;
    padding: 2px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: transparent;
    }
  }

  .active > .page-link {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
    color: $white-color !important;
    padding: 2px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2 !important;
    span {
      color: transparent;
    }
  }
  .page-item:first-child,
  .page-item:last-child {
    .page-link {
      padding: 4px !important;
    }
  }
  .pagination {
    --bs-pagination-border-color: none !important;
    margin: 2px 4px;
    gap: 2px;
    align-items: center !important;
    > li.page-item > a[aria-label="Last"] ::after {
      margin-left: -14px;
      content: url("../../../../../src/Presentations/WebApps/JMS/src/assets/svg/right-double-arrow.svg");
      position: absolute !important;
      right: 6px !important;
      top: 5px !important;
      box-shadow: none !important;
      outline: none !important;
    }
    > li.page-item > a[aria-label="First"] ::after {
      content: url("../../../../../src/Presentations/WebApps/JMS/src/assets/svg/left-double-arrow.svg");
      position: absolute !important;
      right: 6px !important;
      top: 5px !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
  .pagination-sm {
    --bs-pagination-padding-x: 0.4rem;
    --bs-pagination-padding-y: 0.1rem;
  }
}

.pagination_border {
  border-radius: $border-radius-5px;
  border: 1px solid $color-primary !important;
}

.page-size-dropdown {
  color: $theme-color-dark-gray;
  font-family: $theme-font-family-SourceSansPro-Regular;
  border: none;

  &:focus {
    outline: none;
  }
}

// ******pagination css end******

// custon ngb_DropdownContainer
.common_ngb_DropdownContainer {
  .form-control-sm {
    min-height: 0px !important;
  }
}

// .nav-tabs a.nav-link{
//     font-family: $theme-font-family-SourceSansPro-Semibold;
//     color:$theme-color-blue;
//     font-weight: unset !important;
//     padding: 2px 12px !important;
//     letter-spacing: normal !important;
//     &:hover{
//         color: white !important;
//         background-color: $color-primary !important;
//         border-color: $color-primary !important;
//         border: 1px solid $color-primary;
//     }
// }

.blueText_white_bg_button {
  font-family: $theme-font-family-SourceSansPro-Semibold;
  color: $theme-color-blue;
  font-weight: unset !important;
  // padding: 2px 12px !important;
  padding: 4px 16px !important;
  letter-spacing: normal !important;
  &:hover {
    color: white !important;
    background-color: $color-primary !important;
    border-color: $color-primary !important;
    border: 1px solid $color-primary;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1145px) {
  .nav-tabs a.nav-link {
    padding: 2px 8px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .nav-tabs a.nav-link {
    padding: 2px 6px !important;
  }
}

// custon ngb_DropdownContainer end
/* parent-component.scss */
.app-svg-icon .svg-icon {
  transition: transform 0.2s;

  &:hover {
    fill: attr(data-hover-color);
    transform: scale(1.1);
  }
}

// customer details navtab
.customer_nav-tabs a.nav-link {
  font-family: $theme-font-family-SourceSansPro-Semibold;
  color: $theme-color-blue;
  font-weight: unset !important;
  padding: 2px 14px 2px 0px !important;
  letter-spacing: normal !important;
}

// *****sale order svg hover start******
.Transaction_svg {
  app-svg-icon {
    #Component_53_1 {
      transition: transform 0.2s;

      &:hover {
        fill: $theme-color-green;
        transform: scale(1.1);
      }
    }

    #Component_58_1 {
      transition: transform 0.2s;

      &:hover {
        fill: $theme-color-light-green;
        transform: scale(1.1);
      }
    }

    #Component_55_1 {
      transition: transform 0.2s;

      &:hover {
        fill: $theme-color-sky-blue;
        transform: scale(1.1);
      }
    }

    #Component_54_1 {
      transition: transform 0.2s;

      &:hover {
        fill: $theme-color-orange;
        transform: scale(1.1);
      }
    }

    #Component_56_1 {
      transition: transform 0.2s;

      &:hover {
        fill: $theme-color-light-red;
        transform: scale(1.1);
      }
    }

    #Component_57_1 {
      transition: transform 0.2s;

      &:hover {
        fill: $color-primary;
        transform: scale(1.1);
      }
    }
    #Component_79_23 {
      transition: transform 0.2s;

      &:hover {
        fill: $color-secondary;
        transform: scale(1.1);
      }
    }
    #Component_56_80 {
      transition: transform 0.2s;

      &:hover {
        stroke: $theme-color-orange;
        transform: scale(1.1);
      }
    }
    #Component_57_74 {
      transition: transform 0.2s;

      &:hover {
        stroke: $color-primary;
        transform: scale(1.1);
      }
    }
    #Component_57_63 {
      transition: transform 0.2s;

      &:hover {
        stroke: $theme-color-sky-blue;
        transform: scale(1.1);
      }
    }
  }
}
#Component_79_23 {
  cursor: pointer;
  border-radius: 5px;
}
#Component_79_23:hover {
  background-color: $color-primary; 
}
#Component_79_23:hover #Rectangle_17865 {
  fill: $color-primary; 
}
#Component_79_23:hover #Group_5508,
#Component_79_23:hover #Path_2927 , 
#Component_79_23:hover #Path_2926{
  fill: #ffffff;
}


// *****sale order svg hover end******

// modal-popup css by pranay
.modal {
  .modal-content {
    border-radius: 0.9rem;
    padding: 0.4rem 0.5rem;

    .modal-header {
      border: 2px solid $color-primary;
      background-color: $theme-lightergray-bg-color;
      border-radius: 6px;
      padding: 0.4rem 0;

      .cross-button-border {
        position: absolute;
        right: 31px;
        top: 0;
      }

      .cross-button {
        margin: 0.5rem;
        position: absolute;
        right: 0;
        height: 15px;
        cursor: pointer;
      }
    }

    .modal-body {
      padding: 0.65rem 0;
      .pb-065rem {
        padding-bottom: 0.65rem;
      }
      .pt-065rem {
        padding-top: 0.65rem;
      }
      .mb-065rem {
        margin-bottom: 0.65rem;
      }
      .mb-0_35rem {
        margin-bottom: 0.35rem;
      }
    }

    .modal-footer {
      padding: 0;
      border-top: 1px solid $input-br-color;
    }
  }
}

.ManufacturingPopUps {
  .modal-dialog {
    max-width: 1300px !important;
  }
}
.RetailerPopUps {
  .modal-dialog {
    max-width: 100% !important;
  }
}
//delete popup custom width start
.delete_popup {
  min-width: 21.875rem;
}
.save_popup {
  min-width: 21.875rem;
}
.image_popup {
  min-width: 25.875rem;
}

.image_popup_fullscreen {
  .modal-content {
    background-color: black;
  }
  .pic-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 98vh;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 5% !important;
    opacity: 0.7 !important;
  }
  // .carousel-indicators{
  //     background-color: #a1a1a1 !important;
  // }
}

.jewellery_img {
  svg {
    height: 25px !important;
  }
}
//delete popup custom width end
.account-ledger-height{
  .modal-content{
    height: 60vh;
  }
}
//carasoul custum css start
.custom-carousel {
  .carousel-control-prev {
    left: -100%;
    height: fit-content;
    width: fit-content;
    top: 46%;
  }
  .carousel-control-next {
    right: -100%;
    height: fit-content;
    width: fit-content;
    top: 46%;
  }
}

//carasoul custum css end

// settings page (set software) tabs details start // tabs pane height set 100% in ngb tabs
.selected_tab_info,
.administration_master_tabs {
  .tab-pane {
    height: 100% !important;
  }
}
// settings page set software tabs details end

// for sale bill page
#sale_bill_grid {
  app-ag-grid {
    ag-grid-angular {
      height: 200px !important;
      --ag-alpine-active-color: #2196f305;
    }
  }
}
#sale_bill_Payment {
  .ag-theme-alpine {
    --ag-alpine-active-color: transparent !important;
    --ag-range-selection-border-color: transparent !important;
  }
}
#sale_bill_pagination {
  .pagination {
    --bs-pagination-padding-x: 0.4rem !important;
    --bs-pagination-padding-y: 0.1rem !important;
  }
}

.expandable-grid {
  .ag-cell {
    --ag-line-height: 30px !important;
  }
}
.ag-cell-value,
.ag-group-value {
  overflow: visible;
  padding: 0px !important;
}
.sale-bill-columns {
  app-custom-header {
    .wrapper {
      div {
        justify-content: end !important;
      }
    }
  }
}
// ashvini gautam ---

//custom select 2 dropdown custom scss start
.custom-select-2 {
  .select2-container {
    .select2-dropdown {
      border-color: $theme-border-color !important;
      .select2-search {
        .select2-search__field {
          border-color: $theme-border-color !important;
          color: $theme-color-dark-gray !important;
          font-family: SourceSansPro-Regular !important;
          font-size: $font-12 !important;
        }
      }
      .select2-results {
        .select2-results__options {
          .select2-results__option {
            color: $theme-color-dark-gray !important;
            font-family: SourceSansPro-Regular !important;
            font-size: $font-12 !important;
          }
          .select2-results__option[aria-selected="true"] {
            background-color: $theme-border-color !important;
          }
        }
      }
    }
    .select2-selection--single {
      height: 28px !important;
      padding: 0px;
      border-radius: 8px !important;
      border: 1px solid $theme-border-color !important;
      .select2-selection__rendered {
        color: $theme-color-dark-gray !important;
        font-family: SourceSansPro-Regular !important;
        font-size: $font-12 !important;
      }
    }
  }
}
//custom select 2 dropdown custom scss end
.timee {
  input[type="time"]::-webkit-calendar-picker-indicator {
    background-image: url("../../../WebApps/JMS/src/assets/svg/time.svg");
    background-repeat: no-repeat;
    background-position: center;
    height: 36px;
    width: 25px;
    cursor: pointer;
    background-color: $theme-lightergray-bg-color;
    zoom: 1;
  }
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background-image: url("../../../WebApps/JMS/src/assets/svg/time.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 20px;
  width: 15px;
  cursor: pointer;
  background-color: $theme-lightergray-bg-color;
  zoom: 1;
}

// sweet alart
.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-top: 15px;
  margin-bottom: 7px;
  //margin: 0.5rem auto;
  border: 0.25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.swal2-html-container {
  color: $color-secondary;
  font-size: $font-12;
  font-family: "SourceSansPro-Semibold";
  padding: 0 9px !important;
}

.swal2-actions {
  margin-top: 1em;
}
.swal2-styled {
  margin: 0.3125em;
  padding: 5px 11px;
  font-size: 13px !important;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
  cursor: pointer;
  background-color: $color-primary !important;
}

.p-14 {
  padding: 14px !important;
}

//sweet alart

// radio button
.radio_Button {
  // zoom: 1.2;
  cursor: pointer;
  margin-right: -5px !important;
  &:checked {
    accent-color: $color-primary;
  }
  &:not(:checked) {
    accent-color: gray;
  }
}

.radio_Button:not(:checked)::before {
  background-color: gray; /* Change this to the color you want for the inner dot */
}

.wrap-header-cell {
  app-custom-header {
    .wrapper:first-child div {
      justify-content: end !important;
    }
  }
}

//ng multiselect required start
.ng-multiselect-requried {
  position: absolute;
  .form-control {
    height: 1px !important;
    border: 0 !important;
    padding: 0 !important;
    color: transparent !important;
  }
}
//ng multiselect required end

.delet-confirm-popup-w {
  --bs-modal-width: 370px;
  .modal-dialog {
    max-width: 370px !important;
    .modal-content {
      padding: 0px !important;
    }
  }
}

//add image popup
.AddImagePopUp {
  .modal-dialog {
    max-width: 600px !important;
  }
}

//product-opeaning manufacturing Tabs
.ngbNav-tabs ul li a {
  border-radius: 0 !important;
}

.ngbNav-tabs ul li:first-child a.nav-link.active,
.ngbNav-tabs ul li:first-child a:hover {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.ngbNav-tabs ul li:last-child a.nav-link.active,
.ngbNav-tabs ul li:last-child a:hover {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
//product-opeaning manufacturing Tabs

*:focus {
  outline: none;
}

app-company-registration,
app-bank-details-registration,
app-user-details-registration {
  .form-control {
    background-color: transparent !important;
    color: #f1edff !important;
    border: 1px solid #f1edff !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  }
  .form-select {
    background-color: transparent !important;
    color: #f1edff !important;
    border: 1px solid #f1edff !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  }
}

input[type="date"] {
  appearance: none;
  -moz-appearance: textfield;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  // height: 20px;
  // width: 100px;
  cursor: pointer;
}

.border-red {
  .form-select {
    border-color: $theme-primary-red !important;
  }
  .commonInputContainer {
    .form-control {
      border-color: $theme-primary-red !important;
    }
  }
}

app-login {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("./assets/SVGicon/down-icon.svg");
    background-repeat: no-repeat;
    background-position-y: 11px;
    background-position-x: 98%;
    border: 1px solid #cfd3dc;
  }
  .arrowStyle{
    background-position-x: 95% !important;
  }
}

.form-control:disabled {
  background-color: $theme-light-voilet-color !important;
}

.pagination_div {
  border: 1px solid $theme-border-color-1;
  padding: 0px 6px;
  border-radius: $border-radius-8px;
  width: 100%;
  .page-size-control {
    border-left: 1px solid $theme-border-color-1;
    border-right: 1px solid $theme-border-color-1;
  }
}
.hidden-row {
  display: none !important;
}

// report invoice css
.Report_invoice {
  .pagination_div {
    border: 1px solid $theme-border-color-1;
    padding: 2px 6px;
    border-radius: $border-radius-8px;
    width: 100%;
    .page-size-control {
      border-left: 1px solid $theme-border-color-1;
      border-right: 1px solid $theme-border-color-1;
    }
  }

  .invoice_table {
    border-spacing: 0 15px;
    border-collapse: separate;
    tbody {
      tr {
        td {
          border-top: 1px solid $theme-border-color;
          border-bottom: 1px solid $theme-border-color;
          &:first-child {
            border-left: 1px solid $theme-border-color;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }
          &:last-child {
            border-right: 1px solid $theme-border-color;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
  .internal_fix_label {
    top: -10px;
    z-index: 2;
    background-color: $white-color;
    border-radius: $border-radius-5px;
    text-align: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%) !important;

    &.purchase_class {
      border: 0.5px solid rgba(88, 67, 207, 100%);
      color: $color-primary;
    }
    &.sale_class {
      color: $theme-pink-color;
      border: 0.5px solid rgba(209, 45, 176, 100%);
    }
  }

  *::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  *::-webkit-scrollbar {
    width: 1px;
  }

  .no-record-item {
    height: calc(100vh - 220px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .table_div {
    height: 60px;
    width: 100%;
  }
}

.font-family-text-color{
  font-family: $theme-font-family-SourceSansPro-Regular !important;
}

.print-popup {
  .modal-dialog {
    max-width: 100vw;
    // max-height: 100vh;
    margin: 0;
  }
}

.refer_And_scan-popup {
  .modal-dialog {
    max-width: 70vw;
    // max-height: 100vh;
    margin: auto;
  }
}

.KeybordShortcut-popup {
  .modal-dialog {
    max-width: 650px;
    margin: auto;
  }
}

.IpAdderss-popup {
  .modal-dialog {
    max-width: 400px;
    margin: auto;
  }
}
.item_list_table,
.tax_list_table {
  height: 197px;
}
.payment_list_table {
  height: 95px;
}
.no-border-style {
  border-right: none !important;
}

body {
  .dropdown-menu {
    color: black;
  }
  .form-label {
    margin-bottom: 0.2rem;
    // margin-left:   0.3rem ;
    margin-left: 10px;
    color: $color-secondary;
    font-family: $theme-font-family-SourceSansPro-Regular !important;
    font-size: $font-12;
    .required-color {
      color: $theme-primary-red;
    }
  }
  //Range calender css
  .range-datepicker {
    height: 28px;
    border-radius: 8px !important;
    font-family: $theme-font-family-SourceSansPro-Regular !important;
    font-size: 12px !important;
    width: 100%;
    padding: 6px 10px;
    border-radius: 5px;
    // height: 32px;
    height: 29px;
    border: 1px solid $theme-border-color-1 !important;
  }
  ngb-datepicker {
    border: 1px solid $theme-border-color-1 !important;
    padding: 0.4rem 0 0 0 !important;
    width: auto;
    font-family: $theme-font-family-SourceSansPro-Regular !important;
    font-size: 12px;
    color: $color-secondary;
    z-index: 2;
  }

  .ngb-dp-header {
    border-radius: 20px;
    // padding-top: 15px;
  }

  .ngb-dp-months {
    justify-content: center;
    padding: 0 10px;
  }

  .ngb-dp-weekday {
    color: $color-secondary;
    font-size: 11px;
  }

  .ngb-dp-arrow {
    display: flex;
    align-items: center;
    padding-right: 9px;
    padding-left: 9px;
    width: 6rem;
  }

  .ngb-dp-arrow.right {
    justify-content: flex-start;
  }

  .ngb-dp-arrow-btn {
    width: 24px;
    height: 24px;
    // margin: 0 1rem 0 1rem;
    border: none;
    border-radius: 8px;
    background-color: $color-secondary;
    color: #fff;
    line-height: 1.5;
    // padding: 0px 5px !important;
    padding: 0px 2px !important;
  }

  .ngb-dp-navigation-chevron {
    position: relative;
    width: 0.5em;
    height: 0.5em;
    color: white;
    margin-bottom: 1px;
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .ngb-dp-month-name {
    margin: auto;
    max-width: 8rem;
    min-width: 8rem;
    color: $color-secondary;
    font-size: 12px;
    font-weight: 600;
  }

  .btn-light:not([disabled]):not(.disabled).active:hover,
  .btn-light:not([disabled]):not(.disabled).active.active {
    background-color: $color-primary !important;
    color: white;
  }

  .ngb-dp-weekdays {
    font-size: 12px;
    border-bottom: 0;
  }

  .ngb-dp-navigation-select {
    display: flex;
    // flex: 1 1 6rem;
    flex: 1 1 11rem;
    gap: 5px;
  }

  .form-select {
    background-position: right 0.4rem center !important;
    font-size: 10px;
  }

  .ngb-dp-today {
    border: 1px solid $color-primary;
    color: $color-primary;
    background-color: transparent;
    border-radius: 4px;
  }

  .ngb-dp-month:first-child .ngb-dp-week {
    text-align: center;
    color: $color-primary;
  }

  .input-group {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    // column-gap: 6px;
    input {
      padding: 0 !important;
    }
  }

  .custom-day {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0;
    height: 1.5rem;
    width: 1.5rem;
    font-size: $font-11;
    color: $color-secondary;
    line-height: 1.5rem;
  }

  .custom-day:hover {
    background-color: $color-primary;
    color: white;
    border-radius: 50%;
  }

  .custom-day.focused {
    background-color: $color-primary;
    color: white;
    height: 1.5rem;
    width: 1.5rem;
  }

  .custom-day.range {
    background-color: $color-primary;
    color: white;
    border: 1px dashed rgba($color-primary, 0.7);
    border-radius: 0;

    &.start-date {
      border-left: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.end-date {
      border-right: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .custom-day.faded {
    border-radius: 0;
    background-color: rgba($color-primary, 0.3);
    border: 1px dashed rgba($color-primary, 0.7);
    border-left: none;
  }

  // .ngb-dp-week .ngb-dp-day .range.faded:last-child{
  //     border-right: none;
  // }

  .custom-select {
    margin: 3px;
  }

  .btn-calendar {
    display: flex;
    align-items: center;
    height: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;

    svg {
      width: 100%;

      &:hover * {
        fill: $color-primary;
      }
    }
  }

  .input-calendar {
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 4px 10px;
    margin: 0;
    height: 100%;
    width: 100%;
    font-size: $font-12;
    font-family: $theme-font-family-SourceSansPro-Regular;
    letter-spacing: normal;
    color: $color-secondary;
    pointer-events: none;
  }

  .input-calendar::placeholder {
    font-size: $font-11 !important;
    font-family: $theme-font-family-SourceSansPro-Regular !important;
    color: $color-secondary;
  }
  .dropdown-menu.show {
    .btn-calendar svg * {
      fill: $color-primary;
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    column-gap: 10px;

    .btn {
      border: none;
      border-radius: 0.5rem;
      padding: 0.3rem 1.5rem;
      font-size: 0.9rem;

      &.btn-sm {
        padding: 0.25rem 1rem;
        font-size: 0.75rem;
      }
      &.btn-gray {
        background-color: $color-secondary;
        color: #fff;

        &:hover {
          box-shadow: 0 0 3px 0 $color-secondary;
        }
      }
      &.btn-blue {
        background-color: $color-primary;
        color: #fff;

        &:hover {
          box-shadow: 0 0 3px 0 $color-primary;
        }
      }
    }
  }
  input + ngb-datepicker {
    border-radius: 0;
  }

  ngb-datepicker-month {
    .ngb-dp-week:not(.ngb-dp-weekdays):nth-child(2) {
      justify-content: flex-end;
    }

    .ngb-dp-week:not(.ngb-dp-weekdays):nth-last-child(2) {
      justify-content: flex-start;
    }
  }

  @media only screen and (max-width: 450px) {
    .range-datepicker {
      ngb-datepicker {
        border: 1px solid $color-secondary;
        border-radius: 8px;
        padding: 1rem 0 0 0 !important;
        width: calc(100vw - 10px);
        font-family: $theme-font-family-SourceSansPro-Regular;
        font-size: 11px;
        color: $color-secondary;
        margin: 0px -44px !important;
        // min-width: 300px;
      }
    }
  }
}

.custom-deny-button-class {
  background-color: #1492e6 !important; // Set your custom color
  /* Add any other styling as needed */
}

.active-label {
  background-color: lightgreen;
  padding: 10px;
  margin-top: 10px;
}

.Select2-border-red {
  .select2-container .select2-selection--single {
    border: 1px solid $theme-color-red !important;
  }
}

.scanned_row_color {
  background-color: #defcca !important;
}
.green-row {
  background-color: #d4edda !important;
}

.red-row {
  background-color: #f8d7da !important;
}

.redColor {
  background-color: red !important;
  color: white !important;
}

//   .redColor .ag-grid-angular .ag-center-cols-container .ag-row .ag-cell
//   {
//     color : white !important;
//   }

// .custom-datepicker-icon::before {
//     content: url('./assets/images/svg-icon/datepicker_icon.svg');
//     display: inline-block;
//     margin-right: 5px;
//     position: absolute;
//     right: 0px;
// }

.Multiselect {
  .multiselect-dropdown {
    .dropdown-list {
      position: fixed !important;
      width: 34% !important;
    }
  }
}

.iti {
  display: block !important;
  margin-bottom: 20px;
}

ngx-intl-tel-input input {
  height: 30px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
}

ngx-intl-tel-input .iti__selected-flag {
  border-right: 1px solid #c7cace;
}

.catalogue-main {
  background-color: #f6f6f6 !important;
  height: 100vh !important;
  .catalogue-box {
    app-jewelry-catalogue-common {
      .container-fluid {
        height: calc(100% - 43px) !important;
      }
    }
    box-shadow: 0px 1px 8px -2px rgba(112, 112, 112, 0.4509803922);
    border-radius: 16px;
    background-color: white !important;
    height: 100%;
  }
}

//custom select dropdown set width 100%
.select2-container.select2-container-dropdown.select2-container--open {
  width: 100% !important;
}
//
.tab-content {
  .tab-pane {
    height: 100%;
  }
}

// For Permission Page
//***custom ngx datatable component***
//***custom ngx datatable component***
.ngxdt {
  display: flex;
  flex-direction: column;

  > * {
    font-family: $theme-font-family-SourceSansPro-Regular;
  }

  .ngxdt-body {
    padding: 0;

    > * {
      font-size: 11px;
      letter-spacing: initial;
      color: $dark-border-color;
    }
  }
}

//***/custom ngx datatable component***

//***ngx datatable***
.ngx-datatable.bootstrap {
  // variables
  $row-height: 30px;
  $cell-padding: 0 0.75rem;
  $border-color: #cecece;

  min-height: calc(100vh - 206px);
  // overflow: auto !important;

  .datatable-header {
    width: max-content !important;
    height: unset !important;
    overflow: none !important;

    .datatable-header-inner {
      font-weight: 600;
      color: $theme-text-primary-blue;
      border-bottom: 2px solid $border-color;
      width: 100% !important;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    .datatable-header-cell {
      padding: $cell-padding;
      border: none;

      .datatable-header-cell-label {
        font-size: 12px;
      }

      // to display first column data in center
      &.make-center .datatable-header-cell-template-wrap {
        display: block;
        text-align: center;
      }
    }
  }

  .datatable-body {
    width: max-content !important;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 250px);

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .datatable-scroll {
      display: initial;
      overflow-y: auto;
      overflow-x: auto;
      height: calc(100vh - 260px);
      width: 100% !important;

      .datatable-row-wrapper {
        min-width: 100% !important;
        width: max-content !important;
        //   display: table-caption !important;
      }
    }

    .empty-row {
      color: #6c757d;
      text-align: center;
    }

    .datatable-body-row {
      vertical-align: top;
      border-top: none;
      border-bottom: 1px solid $border-color;
      min-width: fit-content;

      cursor: default !important;
      background-color: #fff !important;
      min-width: 100% !important;

      &.active {
        background-color: rgba(239, 239, 239, 0.4) !important;
        color: $dark-border-color !important;
      }

      &:hover,
      &[ng-reflect-is-selected="true"]:hover {
        background-color: rgba(239, 239, 239, 0.4) !important;
        color: $dark-border-color !important;
      }

      .datatable-body-cell {
        padding: $cell-padding;
        line-height: $row-height;

        //to display column data in center
        &.make-center .datatable-body-cell-label {
          display: block;
          text-align: center;
          height: 100%;
        }
      }

    }

    .datatable-group-header {
      border-bottom: 1px solid $border-color;
      height: $row-height;
      width: 100% !important;
      min-width: fit-content;
      > * {
        height: $row-height;
      }

      .group-header-wrapper {
        display: flex;
        align-items: center;
        width: 100% !important;

        > div {
          padding: 0 0.75rem;
          line-height: $row-height;
        }

        .index-column {
          flex: 0 0 70px;
          max-width: 70px;
          text-align: center;
        }

        .group-column {
          cursor: pointer;
          flex: 0 0 270px;
        }

        .checkbox-column {
          flex: 0 0 120px;
          text-align: center;
          height: 100%;
        }
      }
    }
  }

  .datatable-footer {
    background-color: white;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;

    .datatable-footer-inner {
      justify-content: center;
      display: flex !important;
      align-items: center !important;
      width: 100% !important;

      .page-size-control {
        position: absolute;
        left: 40px;
        color: $dark-border-color;

        .page-size-dropdown {
          height: 22px;
          padding: 0.2rem;
          line-height: 1.5;
          border-radius: 0.2rem;
          color: $dark-border-color;
          font-weight: 600;
          border: none;
          background-color: #d1d4d7;
        }
      }

      .pagination-control {
        .page-link {
          padding: 0.25rem 0.5rem;

          span {
            font-family: sans-serif;
            font-size: 15px;
            line-height: 0.9;
            vertical-align: middle;
          }
        }

        .page-item.active .page-link {
          background-color: $theme-text-primary-blue !important;
          border-color: $theme-text-primary-blue !important;
          color: #fff !important;
        }
      }
    }
  }
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
  margin: 12px 6px !important;
  white-space: nowrap !important;
}

@media screen and (max-width: 768px) {
  .ngx-datatable.bootstrap {
    .datatable-body {
      .datatable-scroll {
        .datatable-row-wrapper {
          display: table-caption !important;
        }
      }
    }
  }

  .index-column {
    flex: 0 0 50px !important;
    max-width: 50px !important;
  }
}

@media screen and (max-width: 576px) {
  .index-column {
    flex: 0 0 30px !important;
    max-width: 30px !important;
  }
}

//***/ngx datatable***

//***Applicant list***

//***/Applicant list***

// user-management table footer //

.ngxdt.user-table,
.ngxdt.stages {
  .datatable-footer {
    overflow: hidden !important;

    .datatable-footer-inner {
      position: relative !important;
      top: 12px !important;
    }

    .page-count {
      color: #707070;
    }

    .datatable-pager {
      text-align: center;
    }

    .datatable-pager a {
      color: #707070 !important;
    }

    .datatable-pager ul li:not(.disabled):hover a,
    .datatable-pager ul li:not(.disabled).active a {
      background-color: #0e4ab3 !important;
      color: #fff !important;
      font-weight: bold;
    }
  }
}

/*****custom checkbox*****/
.checkbox {
  &.checkbox-custom {
    $size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    line-height: 1.2;

    label {
      margin-top: 0;
      margin-bottom: 0;
      position: relative;
      margin-left: -$size;
      padding-left: $size;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        margin-left: 0;
        width: $size;
        height: $size;
        border-radius: 2px;
        border-color: $dark-border-color;
        transform: translateY(-50%);
        padding: 2px 0;
        font-size: 8px;
      }
    }

    input[type="checkbox"] {
      width: $size;
      height: $size;
      margin: 0;

      &:checked {
        & + label {
          &::before {
            content: "\e64c";
            border-color: $theme-text-primary-blue;
            color: $theme-text-primary-blue;
          }
        }
      }

      &:disabled {
        & + label {
          opacity: 0.65;

          &::before {
            cursor: not-allowed;
          }
        }
      }
    }

    &.checkbox-dash {
      input[type="checkbox"] + label::before {
        content: "\268a";
        font-size: 10px;
        line-height: 0.9;
      }
    }
  }
}

/*****end custom checkbox*****/
/***** Custom Swal styling *****/
.swal2-container {
  .customSwal-popup-class {
    width: 300px !important;

    .custom-save-icon {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin-bottom: 10px;

      &::before {
        content: '';
        display: inline-block;
        width: 50px;
        height: 50px;
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"  stroke="%2300008B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>') no-repeat center;
        background-size: contain;
      }
    }
    .swal2-confirm{
      font-family: $theme-font-family-SourceSansPro-Regular !important;
      font-size: 15px !important;
      font-weight: 300 !important;
      margin-top: 0 !important;
      color: #000;
    }
    .swal2-title {
      color: $color-secondary !important;
      font-family: $theme-font-family-SourceSansPro-Regular !important;
      font-size: 14px !important;
      font-weight: 300 !important;
      margin-top: 0 !important;
    }
    .swal2-actions {
      margin-top: 0em !important;
    }
    .swal2-yes-button {
      border: 1px solid white;
      border-radius: 6px !important;
      width: 45%;
      margin-top: 4px !important;
      box-shadow: none !important;
      color: $color-primary !important;
      background-color: transparent;

      &:hover {
        background-color: $color-primary !important;
        color: white !important;
      }
    }

    .swal2-no-button {
      border: 1px solid white;
      border-radius: 6px !important;
      width: 45%;
      padding: 6.5px !important;
      box-shadow: none !important;
      color: $theme-light-pink-color !important;
      background-color: transparent;

      &:hover {
        background-color: $theme-light-pink-color !important;
        color: white !important;
      }
    }
  }
}
// border: 1px solid green;
//     color: red;
/***** sweetalert permission popup *****/

//for custom div focus on tab key start
.custom_focus {
  border: 1px solid transparent;
  &:focus {
    border: 1px solid $input-select-focus-color;
  }
}
//  end
// For permission Page

// Export Import css start//
.add_Import_dropdown {
  .dropdown {
    width: 75px;
    &.w_90 {
      width: 90px;
    }
    &.w_110 {
      width: 120px;
    }
  }
  .dropdown-menu {
    box-shadow: 0px 0px 5px #a6a0a0f2 !important;
    min-width: 100% !important;
    z-index: 5 !important;
    .dropdown-item {
      color: $color-primary !important;
      opacity: 1 !important;
      padding: 1px 6px !important;
      border-top: none !important;
      background: $white-color;
      height: 20px;
    }
  }
  .dropdown .dropdown-toggle {
    color: $color-primary !important;
    border-radius: 6px !important;
    padding: 2px 10px !important;
    font-size: 16px !important;
    background-color: $white-color !important;
    border: 1px solid $color-primary;
  }

  .add_ExportImport_button {
    padding: 1.8px 6px;
    #addImport {
      color: $color-primary !important;
    }
    #Union_4 {
      fill: $color-primary !important;
    }
    position: absolute !important;
    left: 4% !important;
    top: 5% !important;
    .btn:hover {
      color: $white-color !important;
      background-color: transparent;
      border-color: transparent !important;
    }
  }
}
.add_Import_dropdown:hover {
  .dropdown .dropdown-toggle {
    color: $white-color !important;
    background-color: $color-primary !important;
  }
  .add_ExportImport_button {
    #addImport {
      color: #ffffff !important;
    }
    #Union_4 {
      fill: #ffffff !important;
    }
  }
}

.add_Export_dropdown {
  .dropdown {
    width: 80px;
  }
  .dropdown-menu {
    box-shadow: 0px 0px 5px #a6a0a0f2 !important;
    min-width: 100% !important;
    z-index: 5 !important;
    .dropdown-item {
      color: $color-primary !important;
      opacity: 1 !important;
      padding: 1px 6px !important;
      border-top: none !important;
      background: $white-color;
      height: 20px;
    }
  }
  .dropdown .dropdown-toggle {
    color: $white-color !important;
    border-radius: 6px !important;
    padding: 2px 10px !important;
    // font-size: 16px !important;
    height: 1.8rem;
    background-color: $white-color;
    border: 1px solid #4f39cd;
  }
  .dropdown .dropdown-toggle:after {
    color: $color-primary !important;
  }

  .add_ExportImport_button {
    padding: 1.8px 6px;
    position: absolute !important;
    left: 4% !important;
    top: 5% !important;
    .btn:hover {
      color: $white-color !important;
      background-color: transparent;
      border-color: transparent !important;
    }
  }
}

// Export Import css end//

//chart css start
.Sale_Person_Dashboard {
  apx-chart {
    .apexcharts-legend {
      display: flex !important;
      justify-content: space-around !important;
      top: 0px !important;

      .apexcharts-legend-series {
        // border: 1px solid #DBDEE5 !important;
        // border-radius: 6px !important;
        // padding: 4px;
        // display: flex;
        // gap: 3px;
        display: none;

        .apexcharts-legend-marker {
          height: 25px !important;
          width: 25px !important;
          border-radius: 8px !important;
        }
      }
    }
  }
}
.donutchart {
  apx-chart {
    .apexcharts-legend-series {
      display: none !important;
    }
  }
}
.piechart {
  apx-chart {
    .apexcharts-legend-series {
      display: none !important;
    }
  }
}
.stock_dashboard {
  apx-chart {
    .apexcharts-legend {
      position: relative !important;
      flex-direction: row-reverse !important;
      justify-content: end !important;
      .apexcharts-legend-series {
        .apexcharts-legend-marker {
          border-radius: 6px !important;
        }
      }
    }
  }
}

.dashboard {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: transparent !important;
  }

  .table-striped tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .table-striped tbody tr:nth-child(even) {
    background-color: #f3f5f8;
  }
}
.borrowers_Report table tbody tr:nth-of-type(odd) {
  background-color: #eef1f7; /* Light gray background for odd rows */
  border-color: $theme-border-color-1;
}

.borrowers_Report table tbody tr:nth-of-type(even) {
  background-color: #ffffff; /* White background for even rows */
  border-color: $theme-border-color-1;
}

//chart css end

//number input css start
.custom-number-input input[type="number"]::-webkit-inner-spin-button,
.custom-number-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: inner-spin-button !important;
  opacity: 1;
}
.custom-number-input input[type="number"] { 
  -moz-appearance: textfield;
}
.custom-number-input input[type="number"]::-moz-inner-spin-button,
.custom-number-input input[type="number"]::-moz-outer-spin-button {
  opacity: 1;
}
//number input css end
// table scroll css start
.table-scroll {
  table {
    width: 100%;
  }

  table th,
  table td,
  table tr,
  table thead,
  table tbody {
    display: block;
  }

  table thead tr {
    width: 100%;
  }

  table tr:after {
    content: " ";
    display: block;
    visibility: hidden;
    clear: both;
  }

  table tbody {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }

  table tbody td,
  table thead th {
    float: left;
  }
}
// table scroll css start

// notification css
.border-left-6 {
  border-left-width: 6px !important;
}

.border-left-blue {
  border-left: 6px solid $theme-color-lightblue !important;
}

.border-left-grey {
  border-left: 6px solid $theme-color-dark-gray !important;
}

.notification-dropdown {
  height: auto;
}

.notification-dropdown.onhover-show-div {
  width: 350px !important;
  right: 10px !important;
  // box-shadow: 0 0 2px rgb(4 123 188) !important;
  box-shadow: 0 0 2px rgba(111, 111, 111, 100%) !important;

  ul {
    height: auto !important;
    overflow: auto;
    //   margin: 2rem 1.4rem !important;
    margin: 2rem 1.4rem 1.5rem 1.4rem !important;
    padding: 0 !important;

    li {
      border-top: 1px solid $theme-color-lightblue;
      border-right: 1px solid $theme-color-lightblue;
      border-bottom: 1px solid $theme-color-lightblue;
      padding: 6px 9px !important;
      background-color: $white-color !important;

      p:first-child {
        text-align: start;

        span {
          font-family: $theme-font-family-SourceSansPro-Semibold;
          font-size: $font-10;
          color: $color-primary;
        }
      }

      p:last-child {
        text-align: start;

        span {
          font-family: $theme-font-family-SourceSansPro-Regular;
          font-size: $font-10;
          color: $theme-color-dark-gray;
          // word-break: break-all;
        }
      }
    }
  }

  .notification-footer-contain {
    margin-bottom: 1.6rem !important;
    padding: 0 2rem !important;

    .notification-footer {
      font-family: $theme-font-family-SourceSansPro-Semibold !important;
      font-size: $font-12 !important;
      color: $color-primary !important;
    }
  }
}

// notification sidebar custom and overight scss start
.offcanvas {
  position: fixed !important;
  top: 70px !important;
  z-index: var(--bs-offcanvas-zindex) !important;
  color: var(--bs-offcanvas-color);
  background-color: var(--bs-offcanvas-bg) !important;
  background-clip: padding-box !important;
  border-top-left-radius: 1.3rem;
  border-bottom-left-radius: 1.3rem;
  box-shadow: 0px 0px 10px 0px rgba(226, 226, 226, 1);
  max-width: 22rem !important;
  border: none !important;
}

.offcanvas-backdrop.fade {
  opacity: 0 !important;
}

.three-dots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.stableScroll {
  scrollbar-gutter: stable both-edges;
}
// notification sidebar custom and overight scss end
#Tota_stcok_val {
  .commonInputContainer .form-control {
    border-radius: 0px !important;
  }
}
.creditScheme .ag_grid_height_pagination {
  height: calc(100vh - 10.5rem) !important;
}
ckeditor {
  ul {
    list-style-type: unset;
    padding-left: 2rem;
  }
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-width: 1px;
  }
}
#Group_4334 {
  stroke: #5843cf !important;
  stroke-width: 0.02rem !important;
}
//sidebar submenu dyanmic height start
.sidebar-submenu {
  max-height: calc(100% - 210px);
  overflow: auto;
}
//sidebar submenu dyanmic height end
.new-loan-popup {
  .modal-dialog {
    max-width: 80% !important;
    margin: auto;
  }
}

.borderred {
  input {
    border: 1px solid red !important;
  }
}
.desabled-delete {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
.Edit_popup {
  .modal-dialog {
    max-width: 80% !important;
    margin: auto;
  }
}

// camera view popup start//
.camview_popup {
  .modal-dialog {
    max-width: 415px;
    .modal-content{
      padding: 0px;
    }
  }
}
.webcam-wrapper video.mirrored {
  transform: scaleX(-0.65) !important;
}
// camera view popup end//

//For common input And Select validation start
.input-border-red {
  .commonInputContainer {
    .form-control {
      border: 1px solid $theme-primary-red !important;
    }
  }

  #datepickerInput {
    .form-control {
      border: 1px solid $theme-primary-red !important;
    }
  }
}

.select-dropdown-border-red {
  .form-select {
    border: 1px solid $theme-primary-red !important;
  }
}

//For common input And Select validation End

.border-radius {
  .commonInputContainer .form-control {
    border-radius: 0px !important;
  }
}

.country {
  app-select-with-icon {
    .text-color-danger {
      margin-right: 15px;
    }
  }
}

.custom-select2-border-red {
  #selec2-2 {
    .select2-selection--single {
      border: 1px solid $theme-primary-red !important;
    }
  }
}

.select-border-red {
  select {
    border: 1px solid $theme-primary-red !important;
  }
}
.multi-select-border-red {
  .multiselect-dropdown {
    .dropdown-btn {
      border: 1px solid $theme-primary-red !important;
    }
  }
}
.large-font-size {
  .commonInputContainer {
    .form-control {
      font-size: 20px !important;
      height: 45px !important;
    }
    ::placeholder {
      font-size: 20px !important;
    }
  }
}
.edit_icon {
  input {
    padding-right: 2rem !important;
  }
}

.modal-backdrop.show {
  opacity: 0.2;
}
.filter-count {
  top: 2px ;
  right: 8.3rem;
  background-color: var(--theme-secondary) !important;
  width: 14px;
  height: 15px;
  text-align: center;
  color: $white-color;
  border-radius: 50%;
}

.common-refresh-size{
  padding: 6px !important;
}
.common-filter-size{
  padding: 4px !important;
}
.bold-text {
  font-weight: bold;
}
//dashboard calender css
.dashboard-datepicker {
  .event-day {
    display: block;
    position: absolute;
    margin-top: -8px;
    padding: 0;
    margin-left: 15px;
    z-index: 4;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background-color: #ff7d00;

    &.hidden {
      display: none;
    }
  }
  //   .selected{
  //     .custom-day{
  //     width: 100% !important;
  //     line-height: 30px !important;
  //     color: #FFFFFF !important;
  //     background-color:#5843CF;
  //     border-radius: 50%;
  //    }
  //   }
  .ngb-dp-today .isSelected {
    // width: 100% !important;
    // line-height: 30px !important;
    color: #ffffff !important;
    background-color: #5843cf;
    border-radius: 50%;
  }
  .ngb-dp-day {
    .custom-day.isSelected {
      // width: 100% !important;
      // line-height: 30px !important;
      color: #ffffff !important;
      background-color: #5843cf;
      border-radius: 50%;
    }
  }
}
//dashboard calender css

//butoon outline css
//   .blue_button {
//     border-color:$color-primary;
//     color: $color-primary;
//     background-color: transparent;
//     border: 1px solid $color-primary;
//     &:hover{
//         color: white;
//         background-color:$color-primary !important;
//         border-color:$color-primary !important;
//         border: 1px solid $color-primary;
//     }
// }
.blue_button,
.add_button {
  border-color: $color-primary !important;
  color: $color-primary !important;
  background-color: white !important;
  border: 1px solid $color-primary;

  .plus-icon,
  .show-print-icon,
  .rest-icon,
  .show-icon,
  .backup-download-icon,
  .import-icon path {
    fill: $color-primary !important; // Set the default fill to the primary color
  }
  #Union_33, #Union_222 {
    fill: $color-primary !important;
  }
  
  &:hover {
    color: white !important;
    background-color: $color-primary !important;
    border-color: $color-primary !important;
    border: 1px solid $color-primary;

    .plus-icon,
    .show-print-icon,
    .rest-icon,
    .show-icon,
    .backup-download-icon,
    .import-icon path {
      fill: white !important; // Change the SVG icon's fill to white on hover
    }
    #Union_33 , #Union_222 {
      fill: white !important;
    }
    #Rectangle_21096{
      stroke : white;
    }
  }
}

.white_button {
  border-color: $color-primary;
  color: $color-primary;
  background-color: transparent;
  border: 1px solid $color-primary;

  .plus-icon,
  .rest-icon,
  .show-icon path {
    fill: $color-primary; // Set the default fill to the primary color
  }

  &:hover {
    color: white;
    background-color: $color-primary !important;
    border-color: $color-primary !important;
    border: 1px solid $color-primary;

    .plus-icon,
    .rest-icon,
    .show-icon path {
      fill: white; // Change the SVG icon's fill to white on hover
    }
  }
}
.save_button{
  border:none;
  background-color: transparent;
}

.light_button {
  border-color: $theme-light-pink-color;
  color: $theme-light-pink-color;
  background-color: transparent;
  border: 1px solid $theme-light-pink-color;

  .plus-icon,
  .rest-icon,
  .show-icon,
  .refresh-icon,
  .filter-icon path {
    fill: $color-primary; // Set the default fill to the primary color
  }

  &:hover {
    color: white;
    background-color: $theme-light-pink-color !important;
    border-color: $theme-light-pink-color !important;
    border: 1px solid $theme-light-pink-color;

    .plus-icon,
    .rest-icon,
    .show-icon,
    .refresh-icon,
    .filter-icon path {
      fill: white; // Change the SVG icon's fill to white on hover
    }
  }
}
.pink_button {
  border-color: $theme-light-pink-color;
  color: $theme-light-pink-color;
  background-color: transparent;
  border: 1px solid $theme-light-pink-color;
  &:hover {
    color: white;
    background-color: $theme-light-pink-color !important;
    border-color: $theme-light-pink-color !important;
    border: 1px solid $theme-light-pink-color;
  }
}
.dark_pink_button {
  border-color: $theme-dark-pink-color;
  color: $theme-dark-pink-color;
  background-color: transparent;
  border: 1px solid $theme-dark-pink-color;
  &:hover {
    color: white;
    background-color: $theme-dark-pink-color !important;
    border-color: $theme-dark-pink-color !important;
    border: 1px solid $theme-dark-pink-color;
  }
}

//butoon outline css
app-export-dropdown:hover {
  .dropdown-toggle {
    color: white !important;
    background-color: $color-primary !important;
    cursor: pointer !important;
    border-radius: 7px;
  }
  .primary-color-1 {
    color: white;
  }
  .add_Export_dropdown .dropdown .dropdown-toggle:after {
    color: white !important;
  }
}

// Common Hover for grids icon 

.deleterows-hover:hover path {
  fill: #dc3545 !important; 
}
.expandIcon-hover:hover path,
.addonIcon-hover:hover path,
.percentageIcon-hover:hover path,
.editrow-hover:hover path,
.barcodePrint-hover:hover path,
.zebraprintIcon-hover:hover path,
.cardprintIcon-hover:hover path,
.import_icon-hover:hover path,
.labelprintIcon-hover:hover path,
.download_icon-hover:hover path,
.scanIcon-hover:hover path,
.penIcon-hover:hover path {
  fill: #de58c2 !important; 
}
.download_icon-hover:hover #Group_40811 path{
  fill: none !important;
  stroke:#de58c2 ;
}

.import_icon-hover:hover rect{
  stroke:#de58c2;
}

#Component_53_5:hover path {
  fill: #de58c2!important;
  stroke: #de58c2!important;
}
#Component_57_1:hover path{
  cursor: pointer;
  fill: #de58c2!important;
  // stroke: #de58c2!important; 
}
// .add_Import_dropdown .dropdown {
//     .dropdown-toggle {
//     color: #FFFFFF !important;
//     border-radius: 6px !important;
//     padding: 2px 10px !important;
//     font-size: 16px !important;
//     background-color: #5843CF !important;
//     }:hover{
//         color: $color-primary !important;
//         background-color: white !important;
//         border: 1px solid $color-primary !important;
//     }
//     .add_ExportImport_button {
//         background-color: #5843CF !important;
//         padding: 1.8px 6px;
//         position: absolute !important;
//         left: 4% !important;
//         top: 5% !important;
//     } :hover{
//         color: $color-primary !important;
//         background-color: white !important;
//         border: 1px solid $color-primary !important;
//     }
// }
// svg icon hover effect
app-svg-icon :hover {
  #Ellipse_7,
  #info_id {
    fill: #5843cf;
  }
  #Union_4 , #Union_396 {
    fill: #ffffff !important;
  }
  #info_i , #scan_i {
    stroke: #ffffff;
  }
}

.pink-hover-icon:hover  path{
  fill: #de58c2 !important;
}
.pink-hover-icon:hover  #Union_4{
  stroke: #de58c2 !important;
}
.pink-hover-icon:hover  #Rectangle_17998-2 {
  stroke: #de58c2 !important;
}
.pink-hover-icon:hover  #Rectangle_18408-2{
  stroke: #de58c2 !important;
}

// Payment-Section Icons
.Payment-svg-hover:hover rect {
  stroke: $theme-light-pink-color !important;
}
.Payment-svg-hover:hover path{
  fill: $theme-light-pink-color !important;
}
// for manufracturer Grid view Svg hover 
.grid-view-container-svg:hover .rect-fill {
  fill: #5843cf !important; 
}
.grid-view-container-svg:hover .inner-fill {
  fill: #ffffff !important; 
}
// for zebra setting icon 
.svg-zebra-container {
  display: inline-block;
  border-radius: 5px;
  background-color: transparent;
  transition: background-color 0.2s ease;
} 
.svg-zebra-container:hover {
  background-color: #5843cf;
}
.svg-zebra-container:hover #Union_258 {
  fill: #ffffff;
}
// For cross icon in modal using Enum
.cross-button {
  transition: transform 0.3s ease;
  &.pink-hover {
    &:hover {
      transform: scale(1.2);
      svg path {
        stroke: #de58c2 !important;
      }
    }
  }
  &.default {
    svg path {
      stroke: #7c89a4 !important;
    }
  }
}

// for Link hover
.link-hover {
  color: #5843cf !important; 
}
.link-hover:hover {
  color: #de58c2 !important; 
}

// ***For all Svg icon using Enum
.default-hover:hover svg path,
.default-hover:hover svg rect {
  fill: #000; /*  for default hover */
}

.pink-hover:hover #Rectangle_18408-2,
.pink-hover:hover #Path_3628,
.pink-hover:hover #Path_3629,
.pink-hover:hover #Path_3630,
.pink-hover:hover svg path,
.pink-hover:hover svg rect {
  fill: #de58c2;
  stroke: #de58c2;
}

.blue-hover:hover #Rectangle_18408-2,
.blue-hover:hover #Path_3628,
.blue-hover:hover #Path_3629,
.blue-hover:hover #Path_3630,
.blue-hover:hover svg path,
.blue-hover:hover svg rect {
  fill: $color-primary; 
}

// **For SVG icon using unique ID
svg:hover #Ellipse_739 {  // for  search-icon
    stroke: #de58c2;
  }
  svg:hover #Line_181 {
    fill: #de58c2;
  }
  #notificationIcon:hover #Rectangle_1101  {
    stroke: #de58c2;
  }
  #notificationIcon:hover #Path_3628,
  #notificationIcon:hover #Path_3629,
  #notificationIcon:hover #Path_3630 {
    fill: #de58c2;
  }

  #unique-svg-icon:hover rect,
  #unique-svg-icon:hover rect {
    stroke: #de58c2; 
  }
  
  #unique-svg-icon:hover path {
    fill: #de58c2; 
  }

  // for Plus icon in grid using unique class
  .plusIcon-hover:hover #Ellipse_7 {
    fill: #5843cf;
  }
  .plusIcon-hover:hover #Ellipse_61 {
    fill: #5843cf;
  }
  .plusIcon-hover:hover #Ellipse_59 {
    fill: #5843cf;
  }
  .plusIcon-hover:hover #Union_4 {
    fill: #ffffff;
  }
  
  .gobackIcon-hover {
    background-color: transparent;
    border-radius: 12px;
    transition: background-color 0.3s ease, fill 0.3s ease;
  }
  .gobackIcon-hover:hover {
    background-color: darkblue;
  }
  .gobackIcon-hover path {
    transition: fill 0.3s ease;
  }
  .gobackIcon-hover:hover path {
    fill: white;
  }
  
  app-refine, app-received{
    app-ag-grid{
      ag-grid-angular{
        .ag-floating-bottom-container{
          top:-3px !important;
        }
      }
    }
  }

  .transition-effect-icon{
    transition: transform 0.3s ease;
    &:hover{
      transform: scale(1.1);
    }
  }
  .select2-container--open 
  {
    z-index: 2 !important;
  }
  .sweetAlertTitle {
    font-size: 18px; /* Decrease the font size */
    font-weight: bold; /* Optional: Change the weight if needed */
    color: #0e4ab3;
  }

  .swalHtml {
    margin: 0 !important; /* Remove or decrease the top margin of the subtitle */
    padding: 0 4px;
  }

  /* This applies to the entire footer actions area */
.swal-custom-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0 !important;
}

/* Yes (Confirm) button - takes 50% of the space */
.swal-custom-confirm-button {
  // background-color: white; /* Purple like Yes */
  color: #5843CF !important;
  flex: 1;
  padding: 10px 0;
  border-radius: 0 0 .25em 0; 
  font-weight: bold;
  border: none;
  text-align: center;
  margin: 0;
}

/* No (Deny) button - takes 50% of the space */
.swal-custom-deny-button {
  // background-color: #f5f6f7; /* Light grey like No */
  color: #DE58C2  !important; /* Dark text */
  flex: 1;
  padding: 10px 0;
  border-radius: 0; /* Remove default rounded corners */
  font-weight: bold;
  border: none;
  text-align: center;
  margin: 0;
}


/* Ensure both buttons take full width */
.swal2-actions {
  padding: 0 !important;
}

/* Add some spacing between the buttons */
.swal-custom-confirm-button + .swal-custom-deny-button {
  margin-left: 0; /* Tiny space between Yes and No */
}

/* Hover effects */
.swal-custom-confirm-button:hover {
  background-color: #4b2bb0 !important; /* Darker purple on hover */
  color: white !important; /* Darker purple on hover */
}

.swal-custom-deny-button:hover {
  background-color: #DE58C2 !important; /* Darker grey on hover */
  color: white !important; /* Darker purple on hover */
}

.swal2-popup {
  padding:  0 !important;
}

.swal2-styled.swal2-confirm/* Light grey like No */
{
  border-radius: 0 0 0 1em; 
}

.swal-custom-deny-button
{
  border-radius: 0 0 1em 0 !important;
}

.swal2-styled
{
  background-color: white !important;
}

/* Remove focus outline from confirm button */
.swal-custom-confirm-button:focus {
  outline: none !important; /* Remove default outline */
}

/* Remove focus outline from deny button */
.swal-custom-deny-button:focus {
  outline: none  !important; /* Remove default outline */
}

/* You can also prevent box-shadow if applicable */
.swal-custom-confirm-button:focus {
  box-shadow: none  !important; /* Remove any box shadow */
}

.swal-custom-deny-button:focus {
  box-shadow: none  !important; /* Remove any box shadow */
}

.swal2-popup
{
  border-radius: 1em;
}

.swal-confirm-error-button{
  border-radius: 0 0 1em 1em !important; 
}

.custom-clear-button {
  background-color: #5843CF !important;
  color: white;
  border-radius: 4px !important; 
  border: none;
}

.custom-cancel-button {
  background-color: #7c89a4 !important;
  color: white;
  border-radius: 4px !important; 
  border: none;
}

.swal-custom-popup {
  min-width: 300px !important; 
  max-width: 900px !important;  
}
.editIconHide{
  .editIcon{
    display: none !important;
  }
}

.overflow-custom {
  scrollbar-width: thin;
  overflow-y: auto !important;
  overflow-x: hidden; 
}

.overflow-custom::-webkit-scrollbar {
  width: 10px !important; 
}
.loading{
  .blue_button {
    background-color:  #5843CF !important;
    .backup-download-icon,
    .import-icon path {
      fill: $color-primary !important;
    }
}
}

.hide_edit_icon{
  .editIcon {
    display: none !important;
  }
  }
  
.connected-icon {
  #Subtraction_1-2 ,#Union_2 {
    fill: green !important;
  }

}

.ag-tooltip {
  white-space: pre-wrap; /* Preserve newlines and wrapping */
}

// full-calendar start----
full-calendar {

  .fc-header-toolbar {
    text-transform: capitalize;
    margin-bottom: 1em !important;
    display: flex;
    flex-wrap: nowrap !important;
    border: 1px solid $theme-border-color;
    border-radius: 10px;
    .fc-toolbar-chunk {
      cursor: pointer !important;
      display: flex;
      flex-wrap: nowrap;
      margin: 4px 21px;

      .fc-today-button {
        border-radius: 8px !important;
        border: 1px solid $theme-border-light-purple-color !important;
        opacity: 1;
        color: $theme-color-dark-gray !important;
        background-color: $theme-bg-light-purple-color !important;
        text-transform: capitalize !important;
      }

      .fc-toolbar-title {
        font-size: 16px;
        font-family: $theme-font-family-SourceSansPro-Semibold !important;
        color: $color-primary !important;
        cursor: pointer;
        position: relative;
      }

      .btn-group {
        border-radius: 8px !important;

        .fc-prev-button {
          padding: 1rem 1rem 1rem 2rem !important;
          background-image: url('../src//assets/svg/left-arrow.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 14px;
        }

        .fc-next-button {
          padding: 1rem 1rem 1rem 2rem !important;
          background-image: url('../src//assets/svg/right-arrow.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 14px;

        }

        button {
          color: $theme-color-dark-gray !important;
          border: 1px solid $theme-border-light-purple-color !important;
          background-color: $theme-bg-light-purple-color !important;
          padding-left: 15px !important;
          padding-right: 15px !important;
          text-transform: capitalize !important;

        }

        .btn-primary:not([disabled]):not(.disabled).active:hover,
        .btn-primary:not([disabled]):not(.disabled).active:focus,
        .btn-primary:not([disabled]):not(.disabled).active:active,
        .btn-primary:not([disabled]):not(.disabled).active.active {
          color: $white-color !important;
          background-color: $color-primary !important;
          border: 1px solid $color-primary !important;
        }
      }
    }
  }

  .fc-popover {
    max-height: 250px;

    .fc-popover-header {
      display: none !important;
    }

    .fc-popover-body {
      max-height: 250px;
      font-family: "Poppins-Regular" !important;
      font-size: 12px !important;
      overflow-y: scroll;
      border: 1px solid $border-color-gray !important;
      border-radius: 10px;

      .fc-daygrid-event-harness a {
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer !important;
        text-wrap: wrap !important;
        border-radius: 10px;
      }

      .fc-daygrid-block-event{
        background:transparent;
        border: 0;
      }
      .fc-daygrid-block-event:hover {
        background: rgba(0, 0, 0, 0.1);
    }
    }

    ::-webkit-scrollbar {
      width: 0px !important;
    }
  }

  .fc-dayGridMonth-view {
    table {
      thead{
        .fc-col-header {
          width: 100% !important;
        }
      }
      tbody{
        .fc-daygrid-body{
          width: 100% !important;
          table{
            width: 100% !important;
          }
        }
      }
     
      border-radius: 10px;

      ::-webkit-scrollbar {
        width: 0px;
      }

      thead {
        .fc-scrollgrid-section-header {
          th {
            text-transform: capitalize;
            // border: 1px solid $color-primary;
            border-top-right-radius: 8px !important;
            border-top-left-radius: 8px !important;
          }
        }

        .fc-col-header-cell {
          padding: 8px 0 8px 0;

          .fc-col-header-cell-cushion {
            text-transform: capitalize;
            color: $theme-color-dark-gray !important;
            font-family: $theme-font-family-SourceSansPro-Semibold !important;
            font-weight: normal !important;
            font-size: 12px;
          }

        }
      }

      tbody {
        .fc-daygrid-block-event{
          background:transparent;
          border: 0;
        }
        .fc-daygrid-block-event:hover {
          background: rgba(0, 0, 0, 0.1);
      } 
        .fc-event {
          cursor: pointer !important;
       }

        .fc-event-main {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .fc-daygrid-day-bottom {
          .fc-daygrid-more-link {
            float: right;
          }
          color: $color-primary !important;
          font-family: $theme-font-family-SourceSansPro-Semibold !important;
          font-size: 12px !important;
        }

        font-family: $theme-font-family-SourceSansPro-Regular !important;
        font-size: 12px !important;

        .fc-scrollgrid-section-body {
          td {
            border-bottom-right-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
          }
        }

        tr {
          .fc-daygrid-body {
            table {
              tbody {
                tr {
                  .fc-day-other {
                    .fc-daygrid-day-top {
                      opacity: 1 !important;
                    }

                    .fc-daygrid-day-number {
                      color: #E2E2E5 !important;
                    }
                  }

                  .fc-daygrid-day-number {
                    color: $theme-color-dark-gray !important;
                    font-size: 12px !important;
                    font-family: $theme-font-family-SourceSansPro-Semibold !important;

                    @media only screen and (max-width:576px) {
                      font-size: 8px !important;

                    }
                  }

                  .fc-daygrid-day.fc-day-today {
                    .fc-daygrid-day-frame { 
                      border: 1px solid $theme-border-color;
                      border-radius: 0px;
                      background-color: $theme-bg-light-purple-color;
                    }
                  }

                  .fc-daygrid-day {
                    .fc-daygrid-day-frame {
                      border: 1px solid transparent;
                    }
                  }

                  .fc-daygrid-day:hover {
                    .fc-daygrid-day-frame {
                      border-color: $theme-color-dark-gray;
                    }
                  }

                }
              }
            }

          }
        }
      }

    }
  }

  // week-view 
  .fc-timeGridWeek-view,
  .fc-timeGridDay-view {

    table {
      // .fc-scroller-harness{
      //   .fc-scroller,.fc-daygrid-body,.fc-timegrid-body{
      //     .fc-col-header  {
      //       width: 100% !important;
      //     }
      //     overflow: scroll !important;
      //     width: 100% !important;
      //     table{
      //       width: 100% !important;
      //     }
      //   }
        
      // }

      border-radius: 10px;

      // width: 625px !important;
      thead {
        .fc-scrollgrid-section-header {
          th {
            border: 1px solid $color-primary;
            border-top-right-radius: 8px !important;
            border-top-left-radius: 8px !important;
          }
        }

        .fc-col-header-cell {
          padding: 8px 0 8px 0;

          .fc-col-header-cell-cushion {
            text-transform: capitalize;
            color: $color-primary !important;
            font-family: $theme-font-family-SourceSansPro-Semibold !important;
            font-weight: normal !important;
            font-size: 12px !important;
          }
        }
      }

      tbody {
        .fc-timegrid-slot-minor {
          border-top-style: solid;
        }

        .fc-scrollgrid-section-body {
          ::-webkit-scrollbar {
            width: 0px;
          }
        }

        .fc-timegrid-slot-lane {
          background-color: transparent !important;
        }

        .fc-event-main {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        font-family: $theme-font-family-SourceSansPro-Semibold !important;
        font-size: 12px !important;

        .fc-timegrid-axis-cushion {
          color: $color-primary !important;
        }

        tr {
          text-transform: capitalize;

          td {
            tbody {
              .fc-timegrid-slot-label-cushion {
                color: $theme-color-dark-gray  !important;

              }

              .fc-timegrid-event-harness-inset .fc-timegrid-event,
              .fc-timegrid-event.fc-event-mirror,
              .fc-timegrid-more-link {
                background-color: white;
                border: none !important;
                box-shadow: none;

              }

              .fc-event-main {
                border-radius: 5px !important;
                cursor: pointer !important;
              }

            }
          }
        }

      }
    }
  }

  //list view
  .fc-listMonth-view {
    font-family: $theme-font-family-SourceSansPro-Semibold !important;
    font-size: 12px !important;
    border-radius: 10px !important;
    border: 1px solid $color-primary !important;

    ::-webkit-scrollbar {
      width: 0px;
    }

    th {
      border-radius: 10px !important;
    }

    tbody {
      .fc-list-event-time {
        color: transparent !important;
        display: none !important;
      }

      .fc-list-day:first-child .fc-list-day-cushion {
        border-top-right-radius: 10px !important;
        border-top-left-radius: 10px !important;
      }
      
      .fc-list-day .fc-list-day-cushion {
        text-transform: capitalize;
        background-color: #F1F1F1 !important;
        color: $color-primary !important;
      }

      .fc-list-sticky {
        border-radius: 10px !important;

      }

      tr {
        th {
          z-index: 3;
        }

        th,
        td {
          .fc-list-event-dot {
            display: none !important;
          }
          border-bottom: 1px solid $color-primary !important;
        }
      }

      .fc-event-main {
        margin-left: -14px;
        cursor: pointer !important;
      }
    }

    .fc-list-empty-cushion {
      color: $theme-color-dark-gray !important;
    }
  }

  @media only screen and (max-width: 658px) {
    .fc-header-toolbar {
      text-transform: capitalize;
      flex-direction: column;
      margin-bottom: 70px !important;
    }

    .fc-today-button {
      position: absolute;
      right: 0;
      width: 100px;
    }

    .btn-group {
      position: absolute;
      left: 0;
    }

    .fc-toolbar-title {
      margin-top: 40px !important;
    }

    .btn-group:last-child {
      margin-top: 10px;
      width: 100%;
    }

  }

  ::-webkit-scrollbar-track {
    margin: 6px 0px;
  }
}

// full-calendar end---


// offcanavas /slider css 
.offcanvas {
    .offcanvas-header {
      background-color: #F3F5F8 !important;
      .cross-button-border {
        position: absolute;
        right: 31px;
        top: 0;
      }
      .cross-button {
        margin: 0.5rem;
        position: absolute;
        right: 0;
        height: 15px;
        cursor: pointer;
      }
    }  

    .offcanvas-body {
      padding: 0.65rem 0.8rem;
      overflow-y: inherit !important;
      .pb-065rem {
        padding-bottom: 0.65rem;
      }
      .pt-065rem {
        padding-top: 0.65rem;
      }
      .mb-065rem {
        margin-bottom: 0.65rem;
      }
      .mb-0_35rem {
        margin-bottom: 0.35rem;
      }
    }

    .offcanvas-footer {
      padding: 0;
      border-top: 1px solid $input-br-color;
    }
}

.offcanvas-custom-class{
  width: 80% !important;
  max-width: none !important;
  position: fixed !important;
  top: 0px !important;
  border-radius: 0px !important;
  z-index: 1055 !important;
}
.offcanvas-custom-backdrop-class {
  &.offcanvas-backdrop{
    &.fade{
      background-color: $theme-color-dark-gray !important;
      opacity: 0.3 !important;
    }
  }
}

.rate-dashboard {
  background-color: #f1edff;
}


// offcanvas end
 


