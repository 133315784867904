.sidebar-wrap{
    .simplebar-content > li  
    {
    padding-top: 2px !important;
    padding-bottom: 0px !important; 
        a{
            display: flex !important;
            gap:2px;
            color:$color-secondary !important;
            font-family: $theme-font-family-SourceSansPro-Regular;
            font-size: $font-16 !important;
            padding:6px 0px !important;
            margin:0px !important;
            border-radius: 0px !important; 
            letter-spacing: 0px !important;
        }
    }
}
.sidebar-wrap {
    .simplebar-content>li:hover {
        a>span {
            color: $color-primary !important;
        }
    }
}
.submenu-title:hover svg path {
    fill: $color-primary !important;
    stroke: $color-primary !important;
    stroke-width: 0.7 !important;
}

a.sidebar-link:hover app-feather-icons svg path {
    fill: $color-primary !important;
    stroke: $color-primary !important;
    stroke-width: 0.7 !important;
}
  
.simplebar-content{
    display:flex;
    align-items: center;
    gap:18px;
}

.sidebar-wrapper {
    .sidebar-main {
        .sidebar-links {
             border-bottom:  1px solid $theme-border-color !important;
            .simplebar-wrapper {
                .simplebar-mask {
                    .simplebar-content-wrapper {
                        .simplebar-content > li:hover .sidebar-submenu{
                            display: none !important;
                        }
                        .simplebar-content > li > a svg {
                            // width: 9px !important;
                            // height: 9px !important;
                            vertical-align: middle;
                            margin-right: 0px !important;
                            // margin-left: 5px !important;
                            margin-bottom: 3px !important;
                            &#dashboard{
                                // width: 12px !important;
                                // height: 12px !important;
                                // margin-left: 0px !important;
                                // margin-bottom: 2px !important;
                                fill:$color-primary ;
                            }
                            // :hover{
                            //     fill:  $color-primary !important; 
                            //     stroke: $color-primary !important; 
                            // }
                        }
                       
                    }
                }
            }
        }
    }
}


.box-layout {
    .page-wrapper.horizontal-wrapper {
        .page-body-wrapper {
            .sidebar-wrapper {
                width: 100% !important;
                .sidebar-main {
                    text-align: left;
                    position: relative;
                }
            }
        }
    }
}

.simplebar-content{
    > li .sidebar-submenu {
        box-shadow: 0px 2px 6px 1px #80808057 !important;
        padding: 6px 3px !important;
        margin-top: 4px !important;
        width: 260px !important;
        li {
            margin: 2px 2px 4px 2px !important;
            a{
                margin: 0 !important;
                border-radius: 5px !important;
                padding: 0 6px !important;
                font-size: $font-14 !important;
                font-family:$theme-font-family-SourceSansPro-Semibold !important;
                app-feather-icons {
                        svg {
                            fill: $color-primary !important;
                            height: 20px;
                        }
                    }
            }
            a.active{
                // background: $theme-light-voilet-color!important;
                background-color: #ebeefe !important;
                color: $color-primary !important;
                font-size: $font-14 !important;
                font-family: $theme-font-family-SourceSansPro-Semibold !important;
                app-feather-icons {
                        svg {
                            fill: $color-primary !important;
                            stroke: $color-primary !important;
                            stroke-width: 1 !important;
                        }
                    }
            }
            a:hover{
                background-color: #ebeefe !important;
                color: $color-primary !important;
                font-size: $font-14 !important;
                font-family: $theme-font-family-SourceSansPro-Semibold !important;
            }
        }
        
    }
        .sidebar-list {
            .sidebar-link {
                border-bottom: 2px solid transparent;
    
                &.active {
                    background-color: transparent !important;
                    border-bottom: 2px solid $color-primary !important;
    
                    span {
                        color: $color-primary !important;
                        font-family: $theme-font-family-SourceSansPro-Semibold;
                    }
    
                    svg {
                        #Path_1294 {
                            fill: $color-primary !important;
                            stroke: $color-primary !important;
                        }
    
                        #dashboard {
                            fill: $color-primary !important;
                        }
                    }

                        app-feather-icons {
                            svg {
                                fill: $color-primary !important;
                                stroke: $color-primary !important;
                                stroke-width: 0.7 !important;
                            }
                        }
                }
            }
        }
}

